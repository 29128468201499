import React, { useState } from 'react';
import { Menu, Divider } from 'antd';
import {
    SettingOutlined,
    LogoutOutlined,
    QuestionCircleOutlined
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../contexts'; 
import { useUserInfo } from '../../../hooks/useUserInfo'; 
import HelpModal from './HelpModal';

interface UserMenuPopupProps {
    onClose?: () => void;
}

const UserMenuPopup: React.FC<UserMenuPopupProps> = ({ onClose }) => {
    const navigate = useNavigate();
    const { logout } = useAuth();
    const { data: userInfo } = useUserInfo();
    const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);
    
    const handleMenuClick = (path: string) => {
        navigate(path);
        onClose?.();
    };
    
    // const handleLogout = async () => {
    //     await logout();
    //     navigate('/signin');
    //     onClose?.();
    // };
    const handleLogout = async () => {
        await logout();
        navigate('/cbetasi');
        onClose?.();
    };
    
    const handleHelpClick = () => {
        setIsHelpModalOpen(true);
        onClose?.();
    };
    
    return (
        <>
            <Menu style={{ width: 200 }}>
            {/* 사용자 정보 섹션 */}
            <div style={{ padding: '12px 16px' }}>
                <div style={{ fontWeight: 'bold' }}>
                {userInfo?.email ? `${userInfo.email.split('@')[0]}님` : '사용자'}
                </div>
                <div style={{ fontSize: '12px', color: '#666' }}>{userInfo?.email}</div>
            </div>
            
            <Divider style={{ margin: '4px 0' }} />
            
            <Menu.Item 
                key="help" 
                icon={<QuestionCircleOutlined />}
                onClick={handleHelpClick}
            >
                도움말
            </Menu.Item>
            
            <Menu.Item 
                key="settings" 
                icon={<SettingOutlined />}
                onClick={() => handleMenuClick('/settings')}
            >
                설정
            </Menu.Item>
            
            <Divider style={{ margin: '4px 0' }} />
            
            <Menu.Item 
                key="logout" 
                icon={<LogoutOutlined />}
                onClick={handleLogout}
            >
                로그아웃
            </Menu.Item>
            </Menu>
    
            <HelpModal 
            isOpen={isHelpModalOpen}
            onClose={() => setIsHelpModalOpen(false)}
            />
        </>
    );
  };
  
  export default UserMenuPopup;