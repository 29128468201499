// 서비스 이용시 활성화
// import React from 'react';
// import { Link } from 'react-router-dom';
// import { useAuth } from '../contexts/AuthContext';
// import { Button, Typography } from 'antd';
// import PinComponent from '../components/PinComponent';
// import DataSharingModal from '../components/ui/modal/DataConsentModal';

// const { Title } = Typography;

// const HomePage: React.FC = () => {
//     const { isAuthenticated } = useAuth();

//     const buttonStyle = {
//         height: '50px',
//         fontSize: '18px',
//         borderRadius: '25px',
//         padding: '0 30px',
//         boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
//         transition: 'all 0.3s ease'
//     };

//     return (
//         <>
//             {!isAuthenticated ? (
//                 <div style={{
//                     display: 'flex',
//                     flexDirection: 'column',
//                     justifyContent: 'center',
//                     alignItems: 'center',
//                     height: '100vh',
//                     width: '100vw',
//                 }}>
//                     <Title level={2} style={{ textAlign: 'center' }}>
//                         클릭 한 번으로 정보 정리<br />
//                         Ceep에 오신 것을 환영합니다.
//                     </Title>
//                     <div style={{ marginTop: '20px' }}>
//                         <Link to="/signin">
//                             <Button type="primary" style={{ ...buttonStyle, marginRight: '15px' }}>
//                                 로그인
//                             </Button>
//                         </Link>
//                         <Link to="/signup">
//                             <Button style={buttonStyle}>회원가입</Button>
//                         </Link>
//                     </div>
//                 </div>
//             ) : (
//                 <>
//                     <PinComponent />
//                     <DataSharingModal />
//                 </>
//             )}
//         </>
//     );
// };

// export default HomePage;

//서비스 비활성화
import React from 'react';
import { useAuth } from '../contexts/AuthContext';
import PinComponent from '../components/PinComponent';
import DataSharingModal from '../components/ui/modal/DataConsentModal';
import MaintenancePage from './MaintenancePage';

const HomePage: React.FC = () => {
    const { isAuthenticated } = useAuth();

    return (
        <>
            {!isAuthenticated ? (
                <MaintenancePage />
            ) : (
                <>
                    <PinComponent />
                    <DataSharingModal />
                </>
            )}
        </>
    );
};

export default HomePage;