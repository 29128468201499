import React, { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Placeholder from '@tiptap/extension-placeholder';
import Highlight from '@tiptap/extension-highlight'
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import Link from '@tiptap/extension-link';
import Image from '@tiptap/extension-image';
import CodeBlockLowlight from '@tiptap/extension-code-block-lowlight'
import { common, createLowlight } from 'lowlight'
import 'highlight.js/styles/github.css'
import { EditorBubbleMenu } from '../components/PieceBodyPage/editor/BubbleMenu'; 
import { Color } from '@tiptap/extension-color';
import TextStyle from '@tiptap/extension-text-style';
import { Piece } from '../types/Picec';
import { Tangle } from '../types/Tangle';
import { Input, Select, Tag, message, Divider } from 'antd';
import { CalendarOutlined, LinkOutlined, TagOutlined, StarOutlined, StarFilled } from '@ant-design/icons';
import { UpdatePiece, UpdatePieceRequest } from '../api/piece';
import { ObjDoc } from '../types/ChildObj';
import { UpdateTangle } from '../api/Tangle';
import { toggleBookmark } from '../api/ChildObj';
import debounce from 'lodash/debounce';

import Table from '@tiptap/extension-table'
import TableRow from '@tiptap/extension-table-row'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'

import BackgroundColor from '../components/PieceBodyPage/editor/backgroundColor';

import { SlashCommand } from '../components/PieceBodyPage/editor/SlashCommand';

import { KeywordsEditor } from '../components/PieceBodyPage/KeywordsEditor';

import TaskList from '@tiptap/extension-task-list'
import TaskItem from '@tiptap/extension-task-item'


const editorStyles = `
  .tiptap {
    padding: 20px;

    min-height: 200px;
    line-height: 1.6;
    outline: none;
  }

  .tiptap p {
    margin-bottom: 0.8em;
  }

  .tiptap img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 1em auto;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .tiptap h1 {
    font-size: 2em;
    margin-top: 1em;
    margin-bottom: 0.5em;
    font-weight: 600;
  }

  .tiptap h2 {
    font-size: 1.5em;
    margin-top: 0.83em;
    margin-bottom: 0.42em;
    font-weight: 600;
  }

  .tiptap blockquote {
    border-left: 4px solid #e2e8f0;
    padding-left: 1em;
    margin: 1em 0;
    color: #4a5568;
  }

  .tiptap ul, .tiptap ol {
    padding-left: 1.5em;
    margin-bottom: 0.8em;
  }

  .tiptap p.is-empty::before {
    content: attr(data-placeholder);
    float: left;
    color: #adb5bd;
    pointer-events: none;
    height: 0;
  }

  .editor-bubble-menu {
    display: flex;
    align-items: center;
    background-color: white;
    padding: 4px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.12);
    border: 1px solid rgba(0, 0, 0, 0.08);
  }

  .menu-item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border: none;
    background: none;
    border-radius: 4px;
    padding: 0;
    margin: 0 2px;
    color: #4b5563;
    cursor: pointer;
    transition: all 0.2s;
  }

  .menu-item:hover {
    background-color: rgba(0, 0, 0, 0.06);
  }

  .menu-item.is-active {
    color: #1a73e8;
    background-color: rgba(26, 115, 232, 0.1);
  }

  .divider {
    width: 1px;
    height: 24px;
    background-color: rgba(0, 0, 0, 0.12);
    margin: 0 4px;
  }

  /* Dropdown styles */
  .dropdown-wrapper {
    position: relative;
  }

  .dropdown-menu {
    position: absolute;
    top: calc(100% + 5px);
    left: 50%;
    transform: translateX(-50%);
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.12);
    padding: 4px;
    z-index: 31;
    border: 1px solid rgba(0, 0, 0, 0.08);
    min-width: auto;
    display: flex;
    gap: 2px;
  }

  .dropdown-item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    border: none;
    background: none;
    cursor: pointer;
    border-radius: 4px;
    color: #4b5563;
    transition: all 0.2s;
    width: 32px;
    height: 32px;
  }

  .dropdown-item:hover {
    background-color: rgba(0, 0, 0, 0.06);
  }

  .dropdown-item.is-active {
    color: #1a73e8;
    background-color: rgba(26, 115, 232, 0.1);
  }

  /* Color picker styles */
  .color-dot {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-left: 4px;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }

  .color-picker {
    position: absolute;
    top: calc(100% + 8px);
    left: 0;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.12);
    padding: 8px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 4px;
    z-index: 20;
  }

  .color-item {
    width: 24px;
    height: 24px;
    padding: 0;
    border: none;
    background: none;
    cursor: pointer;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .color-item:hover {
    background-color: rgba(0, 0, 0, 0.06);
  }

  /* Link styles */
  .link-dropdown {
    position: relative;
  }

  .link-popup {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 8px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.12);
    padding: 8px;
    z-index: 20;
    border: 1px solid rgba(0, 0, 0, 0.08);
    width: 240px;
  }

  .link-popup input {
    width: 100%;
    padding: 6px 8px;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    margin-bottom: 8px;
    font-size: 14px;
  }

  .link-buttons {
    display: flex;
    gap: 8px;
  }

  .link-button {
    flex: 1;
    padding: 6px 12px;
    border: none;
    border-radius: 4px;
    background-color: #2563eb;
    color: white;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.2s;
  }

  .link-button:hover {
    background-color: #1d4ed8;
  }

  .link-button.remove {
    background-color: #ef4444;
  }

  .link-button.remove:hover {
    background-color: #dc2626;
  }

  .editor-link {
    color: #2563eb;
    text-decoration: none;
    border-bottom: 1px solid #2563eb;
  }

  .tiptap p[style*="text-align"] {
    margin-bottom: 0.8em;
  }

  /* 인라인 코드 스타일 */
  .tiptap code {
    background-color: #f3f4f6;
    color: #ef4444;
    padding: 2px 4px;
    border-radius: 4px;
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, monospace;
    font-size: 0.9em;
  }

  /* 코드 블록 스타일 */
  .tiptap pre {
    background-color: #f3f4f6;  
    color: #374151;   
    padding: 1em;
    border-radius: 8px;
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, monospace;
    font-size: 0.9em;
    overflow-x: auto;
    margin: 1em 0;
  }

  .tiptap pre code {
    background-color: transparent;
    color: inherit;
    padding: 0;
    border-radius: 0;
    font-size: 1em;
  }

  /* 선택적: 스크롤바 스타일링 */
  .tiptap pre::-webkit-scrollbar {
    height: 8px;
  }

  .tiptap pre::-webkit-scrollbar-track {
    background: #e5e7eb;
    border-radius: 4px;
  }

  .tiptap pre::-webkit-scrollbar-thumb {
    background: #d1d5db;
    border-radius: 4px;
  }

  .tiptap pre::-webkit-scrollbar-thumb:hover {
    background: #9ca3af;
  }

  /* 테이블 스타일 */
  .tiptap table {
    border-collapse: collapse;
    margin: 1em 0;
    width: 100%;
    font-size: 0.95em;
    font-family: inherit;
  }

  .tiptap th {
    background-color: #f8fafc;
    color: #334155;
    font-weight: 600;
    padding: 12px;
    border: 1px solid #e2e8f0;
  }

  .tiptap td {
    padding: 12px;
    border: 1px solid #e2e8f0;
    color: #475569;
  }

  .tiptap tr:nth-child(even) {
    background-color: #f8fafc;
  }

  .tiptap tr:hover {
    background-color: #f1f5f9;
  }

  .slash-menu {
  position: absolute;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.12);
  padding: 8px;
  z-index: 100;
  border: 1px solid rgba(0, 0, 0, 0.08);
  min-width: 180px;
  }

  .slash-menu-item {
    padding: 8px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.2s;
  }

  .slash-menu-item:hover {
    background-color: #f1f5f9;
  }

  .slash-menu {
  position: absolute;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.12);
  padding: 8px;
  z-index: 100;
  border: 1px solid rgba(0, 0, 0, 0.08);
  min-width: 180px;
}

.slash-menu-item {
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
  user-select: none;
}

.slash-menu-item:hover {
  background-color: #f1f5f9;
}

.slash-menu-item.selected {
  background-color: #e2e8f0;
  color: #1a73e8;
}

.tiptap hr {
  margin: 1.5em 0;
  height: 2px;
  background-color: #e2e8f0;
  border: none;
}

/* 체크리스트 스타일 */
  .tiptap ul[data-type="taskList"] {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .tiptap ul[data-type="taskList"] p {
    margin: 0;
  }

  .tiptap ul[data-type="taskList"] li {
    display: flex;
    gap: 0.5em;
    align-items: flex-start;
    min-height: 24px;
  }

  .tiptap ul[data-type="taskList"] li > div {
    flex: 1;
    margin: 0;
    padding: 0;
  }

  .tiptap ul[data-type="taskList"] input[type="checkbox"] {
    cursor: pointer;
    margin: 3px 0 0;
  }

  .tiptap ul[data-type="taskList"] li[data-checked="true"] > div p {
    color: #9ca3af;  
    text-decoration: line-through;  
    text-decoration-thickness: 1px;  
  }
`;

const isTangle = (item: Piece | Tangle): item is Tangle => {
  return 'pieces' in item;
};

const PieceBodyPage: React.FC = () => {
  const location = useLocation();
  const item = location.state?.piece as (Piece | Tangle);
  const lowlight = createLowlight(common)
  const [title, setTitle] = useState(item?.title || '');
  const [keywords, setKeywords] = useState(!isTangle(item) ? item?.keywords || [] : []);
  const [isBookmarked, setIsBookmarked] = useState(item?.bookmark || false);


  const saveContent = useCallback(
    debounce(async (updates: UpdatePieceRequest) => {
      console.log('saveContent called with:', updates); // 추가
      if (!item?._id) return;
  
      try {
        if (isTangle(item)) {
          await UpdateTangle.updateTangleContent(item._id, { body: updates.body || '' });
        } else {
          await UpdatePiece.updatePieceContent(item._id, updates);
          console.log('Would update with:', updates); // 추가
          
          // 로컬 상태 업데이트
          if (updates.body) item.body = updates.body;
          if (updates.title) item.title = updates.title;
          if (updates.keywords) item.keywords = updates.keywords;
        }
      } catch (error) {
        message.error('저장 중 오류가 발생했습니다.');
      }
    }, 1000),
    [item]
  );

  // 제목 변경 핸들러
  const handleTitleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTitle(e.target.value);
    saveContent({ title: e.target.value }); 
  };


const editor = useEditor({
  extensions: [
    StarterKit.configure({
      // StarterKit에서 기본 codeBlock을 비활성화
      codeBlock: false
    }),
    Table.configure({
      HTMLAttributes: {
        class: 'editor-table',
      },
    }),
    TableRow,
    TableHeader,
    TableCell,
    SlashCommand,
    Image.configure({
      inline: true,
      allowBase64: true,
      HTMLAttributes: {
        class: 'editor-image',
        draggable: 'true',
      },
    }),
    TextStyle,
    Color,
    Underline,
    BackgroundColor,
    Highlight.configure({ multicolor: true }),
    TextAlign.configure({
      types: ['paragraph', 'heading']
    }),
    Link.configure({
      openOnClick: true,
      HTMLAttributes: {
        class: 'editor-link'
      }
    }),
    CodeBlockLowlight.configure({
      lowlight,
    }),
    TaskList,                    
    TaskItem.configure({         
      nested: true,
      HTMLAttributes: {
        class: 'task-item',
      },            
    }),
    Placeholder.configure({
      placeholder: '원하는 기능을 실행하려면 /를 입력하세요.'
    }),
  ],
    content: item?.body || '',
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      // 실제 저장 함수 호출은 주석 처리 (추후 활성화)
      saveContent({ body: html });
    },
  });

  const handleLinkClick = (url: string) => {
    window.open(url, '_blank', 'noopener noreferrer');
  };

  
  const handleToggleBookmark = async () => {
    try {
      if (!item?._id) return;
      
      await toggleBookmark(item as unknown as ObjDoc);
      setIsBookmarked(!isBookmarked);
      
      // 로컬 상태 업데이트
      if (item) {
        item.bookmark = !isBookmarked;
      }
    } catch (error) {
      message.error('북마크 설정 중 오류가 발생했습니다.');
    }
  };

  return (
    <div style={{ maxWidth: '1000px', margin: '0 auto', padding: '20px' }}>
      <style>{`
        ${editorStyles}
        
        .title-textarea:focus {
          box-shadow: none !important;
          outline: none !important;
        }

        .title-textarea .ant-input {
          box-shadow: none !important;
          outline: none !important;
        }

        .title-textarea .ant-input:focus {
          box-shadow: none !important;
          outline: none !important;
        }
      `}</style>

      {item ? (
        <>
          <div className="piece-header" style={{ marginBottom: '2rem' }}>
            <div style={{ display: 'flex', alignItems: 'flex-start', gap: '12px' }}>
              {/* 북마크 아이콘 */}
              <div
                onClick={handleToggleBookmark}
                style={{
                  cursor: 'pointer',
                  fontSize: '1.5rem',
                  display: 'flex',
                  alignItems: 'center',
                  color: isBookmarked ? '#fbbf24' : '#94a3b8',
                  marginLeft: '8px',  
                  marginRight: '4px',
                  marginTop: '8px'
                }}
                onMouseOver={(e) => {
                  if (!isBookmarked) {
                    e.currentTarget.style.color = '#cbd5e1';
                  }
                }}
                onMouseOut={(e) => {
                  if (!isBookmarked) {
                    e.currentTarget.style.color = '#94a3b8';
                  }
                }}
              >
                {isBookmarked ? <StarFilled /> : <StarOutlined />}
              </div>

              {/* 제목 입력 필드 */}
              <Input.TextArea
                value={title}
                onChange={handleTitleChange}
                style={{
                  fontSize: '2.0rem',
                  fontWeight: '700',
                  color: '#2c3e50',
                  marginBottom: '1.5rem',
                  lineHeight: '1.3',
                  border: 'none',
                  padding: 0,
                  resize: 'none',
                  background: 'transparent',
                  boxShadow: 'none',
                  flex: 1
                }}
                className="title-textarea"
                autoSize
              />
            </div>

            <div style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem',
              color: '#64748b',
              fontSize: '0.95rem',
              marginBottom: '1rem'
            }}>
              {/* 생성일 표시 */}
              <div style={{
                display: 'flex',
                alignItems: 'center',
                gap: '0.5rem'
              }}>
                <CalendarOutlined style={{ fontSize: '16px' }} />
                <span>Created {new Date(item.createdAt).toLocaleDateString('ko-KR', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric'
                })}</span>
              </div>

              {/* 수정일 표시 (생성일과 다른 경우에만) */}
              {item.updatedAt && item.updatedAt !== item.createdAt && (
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '0.5rem'
                }}>
                  <CalendarOutlined style={{ fontSize: '16px' }} />
                  <span>Updated {new Date(item.updatedAt).toLocaleDateString('ko-KR', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                  })}</span>
                </div>
              )}

              {/* Tangle이 아닌 경우에만 표시되는 정보들 */}
              {!isTangle(item) && (
                <>
                  {/* 링크 URL이 있는 경우 표시 */}
                  {item.linkUrl && (
                    <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '0.5rem'
                    }}>
                      <LinkOutlined style={{ fontSize: '16px' }} />
                      <a
                        href={item.linkUrl}
                        onClick={(e) => {
                          e.preventDefault();
                          handleLinkClick(item.linkUrl);
                        }}
                        style={{
                          color: '#3b82f6',
                          textDecoration: 'none',
                          fontWeight: '500',
                          transition: 'color 0.2s'
                        }}
                        onMouseOver={(e) => e.currentTarget.style.color = '#2563eb'}
                        onMouseOut={(e) => e.currentTarget.style.color = '#3b82f6'}
                      >
                        원문 보기
                      </a>
                    </div>
                  )}

                  {/* 키워드 편집 영역 */}
                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '0.5rem',
                    flexWrap: 'wrap',
                    padding: 0
                  }}>
                    <KeywordsEditor
                      keywords={keywords}
                      onChange={(newKeywords) => {
                        setKeywords(newKeywords);
                        saveContent({ keywords: newKeywords });
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          </div>

          {/* 구분선 */}
          <Divider style={{
            margin: '0 0 2rem 0',
            borderColor: '#e2e8f0'
          }} />

          {/* 에디터 영역 */}
          {editor && <EditorBubbleMenu editor={editor} />}
          <EditorContent editor={editor} />
        </>
      ) : null}
    </div>
  );
};

export default PieceBodyPage;
