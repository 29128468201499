import axiosInstance from '../utils/axiosInstance';
import axios from 'axios';
import { Tangle, TangleAddPieceRequest, Category } from '../types/Tangle';
import { ObjDoc } from '../types/ChildObj';


interface UpdateTangleRequest {
  body?: string;
  title?: string;
}

interface FindTangleListParams {
  supercategory?: string;
  midcategory?: string;
  subcategory?: string;
  page?: number;
  size?: number;
}

interface TangleListResponse {
  items: Tangle[];
  total: number;
  page: number;
  size: number;
}

// tangle 생성
//title, supercategory, midcategory, subcategory는 Optional입니다. title, supercategory, midcategory, subcategory 필드를 제공하지 않을 경우 각각 pieceIds에 제공된 첫번째 id의 piece의 것을 사용
const createTangle = async (title: string, piecesIds: string[], category: Category) => {
  try {
    const response = await axiosInstance.post('/api/v1/tangle', {
      title,
      piecesIds,
      category: {
        supercategory: category.supercategory,
        midcategory: category.midcategory,
        subcategory: category.subcategory
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error creating tangle:', error);
    throw error;
  }
};


// tangle 조회
export const getTangleList = async (params: FindTangleListParams) => {
  try {
      const query = new URLSearchParams({
          obj_type: 'tangle',
          page: String(params.page || 1),
          size: String(params.size || 15)
      });

      const response = await axiosInstance.get<TangleListResponse>(`/api/v1/objs?${query.toString()}`);

      return response.data;
  } catch (error) {
      throw error;
  }
};



// tangle 삭제
// 이 api는 tangle에 참조하는 piece들도 같이 삭제 죔.
export const tangleDelete = async (objDoc: ObjDoc): Promise<boolean> => {
  try {
      if (!objDoc._id) {
          throw new Error('Invalid objDoc: missing _id');
      }

      const url = `/api/v1/tangle/${objDoc._id}/`;

      await axiosInstance.delete(url);

      return true;
  } catch (error) {
      if (axios.isAxiosError(error)) {
          // console.error('삭제 처리 중 오류 발생:', error);
      }
      return false;
  }
};


// tangle 업데이트
export const UpdateTangle = {
    updateTangleContent: async (tangleId: string, data: UpdateTangleRequest) => {
      try {
        const response = await axiosInstance.patch(`/api/v1/tangle/${tangleId}/`, data);
        return response;
      } catch (error) {
        if (axios.isAxiosError(error)) {
          throw error.response?.data?.message || '서버 오류가 발생했습니다.';
        }
        throw '알 수 없는 오류가 발생했습니다.';
      }
    }
};


// Piece 추가
export const addPiece = {
  addPieceContent: async (tangleId: string, data: TangleAddPieceRequest) => {
    try {
      const response = await axiosInstance.patch(`/api/v1/tangle/${tangleId}/add-piece`, data);
      return response;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw error.response?.data?.message || '서버 오류가 발생했습니다.';
      }
      throw '알 수 없는 오류가 발생했습니다.';
    }
  }
};

