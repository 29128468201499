import React, { useState } from 'react';
import { Card, Tooltip, Dropdown, Menu, message, notification, Button, Popover, Badge } from 'antd';
import { EyeOutlined, StarOutlined, StarFilled, MoreOutlined, DeleteOutlined, EditOutlined, DownloadOutlined, 
  FileExcelOutlined, FileWordOutlined, FilePdfOutlined, FileImageOutlined, FileZipOutlined, 
  FileTextOutlined, FilePptOutlined, FileUnknownOutlined, FileOutlined } from '@ant-design/icons';
import { File } from '../../../types/ChildObj';
import { setDeletedAt, toggleBookmark } from '../../../api/ChildObj';
import { useNavigate } from 'react-router-dom';
import EditModal from '../../ui/modal/EditModal';
import { generatePresignedUrl, updateFile } from '../../../api/File';

const { Meta } = Card;

interface FileCardProps {
  file: File;
  onViewCountChange: (id: string, count: number) => void;
  onBookmarkToggle: (id: string, bookmarked: boolean) => void;
  onDelete: (id: string) => void;
  onEdit: (id: string, newTitle: string, newKeywords: string[]) => void;
}

const FileCard: React.FC<FileCardProps> = ({ 
  file, 
  onViewCountChange, 
  onBookmarkToggle, 
  onDelete, 
  onEdit,
}) => {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  const [viewCount, setViewCount] = useState(file.views || 0);
  const [isBookmarked, setIsBookmarked] = useState(file.bookmark || false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);

  const displayedKeywords = file.keywords?.slice(0, 5) || [];

  const styles = {
    card: {
      position: 'relative' as 'relative',
      overflow: 'hidden',
    },
    fileIconContainer: {
      position: 'relative' as 'relative',
      height: '190px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#f0f0f0',
    },
    overlay: {
      position: 'absolute' as const,
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.85)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      opacity: isHovered ? 1 : 0,
      transition: 'opacity 0.3s ease',
      zIndex: 1,
    },
    keywords: {
      color: 'white',
      textAlign: 'center' as const,
      padding: '10px',
    },
    keyword: {
      display: 'inline-block',
      margin: '0 5px 5px 0',
      padding: '5px 12px',
      backgroundColor: 'rgba(255, 255, 255, 0.15)',
      borderRadius: '20px',
      fontSize: '14px',
      fontWeight: 'bold',
      boxShadow: '0 1px 3px rgba(0, 0, 0, 0.3)',
      transition: 'all 0.3s ease',
    },
    fileIcon: {
      fontSize: '84px',
    },
    actions: {
      position: 'absolute' as const,
      top: '10px',
      right: '10px',
      zIndex: 3,
      display: 'flex',
      gap: '15px',
      transition: 'color 0.3s ease',
      color: isHovered ? 'white' : 'rgba(0, 0, 0, 0.65)',
    },
    icon: {
      fontSize: '20px',
      cursor: 'pointer',
    },
    iconContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      paddingTop: '5px',
      marginBottom: '10px',
      gap: '15px',
    },
    summary: {
      display: '-webkit-box',
      WebkitLineClamp: 3,
      WebkitBoxOrient: 'vertical' as const,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      cursor: 'pointer',
      marginBottom: '8px'
    },
    fullSummary: {
      maxWidth: '300px',
      maxHeight: '200px',
      overflow: 'auto',
      padding: '10px',
    },
  };

  const getFileIcon = (fileType: string) => {
    const iconProps = { style: { ...styles.fileIcon } };
    const lowerCaseType = fileType.toLowerCase();
 
    if (lowerCaseType.includes('excel') || lowerCaseType.includes('spreadsheet') || lowerCaseType.endsWith('xlsx') || lowerCaseType.endsWith('xls')) {
      return <FileExcelOutlined {...iconProps} style={{ ...iconProps.style, color: '#217346' }} />;
    } else if (lowerCaseType.includes('word') || lowerCaseType.includes('document') || lowerCaseType.endsWith('docx') || lowerCaseType.endsWith('doc')) {
      return <FileWordOutlined {...iconProps} style={{ ...iconProps.style, color: '#2b579a' }} />;
    } else if (lowerCaseType.includes('pdf')) {
      return <FilePdfOutlined {...iconProps} style={{ ...iconProps.style, color: '#f40f02' }} />;
    } else if (lowerCaseType.includes('image') || ['jpg', 'jpeg', 'png', 'gif'].some(ext => lowerCaseType.endsWith(ext))) {
      return <FileImageOutlined {...iconProps} style={{ ...iconProps.style, color: '#36cfc9' }} />;
    } else if (lowerCaseType.includes('zip') || lowerCaseType.includes('compressed') || ['zip', 'rar', '7z'].some(ext => lowerCaseType.endsWith(ext))) {
      return <FileZipOutlined {...iconProps} style={{ ...iconProps.style, color: '#ffd591' }} />;
    } else if (lowerCaseType.includes('text') || lowerCaseType.endsWith('txt')) {
      return <FileTextOutlined {...iconProps} style={{ ...iconProps.style, color: '#8c8c8c' }} />;
    } else if (lowerCaseType.includes('powerpoint') || lowerCaseType.includes('presentation') || lowerCaseType.endsWith('ppt') || lowerCaseType.endsWith('pptx')) {
      return <FilePptOutlined {...iconProps} style={{ ...iconProps.style, color: '#F65835' }} />;
    } else if (['hwp', 'hwpx', 'hwpml'].some(ext => lowerCaseType.endsWith(ext))) {
      return (
        <div style={{ position: 'relative', display: 'inline-block' }}>
          <FileOutlined {...iconProps} style={{ ...iconProps.style, color: '#10abeb' }} />
          <span style={{ 
            position: 'absolute', 
            top: '60%', 
            left: '50%', 
            transform: 'translate(-50%, -50%)',
            fontSize: '2.5em',
            fontWeight: 600,
            color: '#10abeb'
          }}>
            H
          </span>
        </div>
      );
    } else {
      return <FileUnknownOutlined {...iconProps} style={{ ...iconProps.style, color: '#8c8c8c' }} />;
    }
  };

  const handleView = () => {
    if (file._id) {
      const newCount = viewCount + 1;
      setViewCount(newCount);
      onViewCountChange(file._id, newCount);
    }
  };

  const handleBookmarkToggle = async (e: React.MouseEvent) => {
    e.stopPropagation();
    if (file._id) {
      try {
        const newBookmarkStatus = await toggleBookmark(file);
        setIsBookmarked(newBookmarkStatus);
        onBookmarkToggle(file._id, newBookmarkStatus);
      } catch (error) {
        message.error('북마크 상태 변경에 실패했습니다.');
      }
    }
  };

  const handleDelete = async () => {
    if (file._id && typeof file._id === 'string') {
      const success = await setDeletedAt(file);
      if (success) {
        notification.open({
          message: '파일이 삭제되었습니다',
          description: '휴지통에 30일 동안 보관되며, 그 후에 영구 삭제됩니다.',
          btn: (
            <Button type="primary" size="small" onClick={() => navigate('/trash')}>
              휴지통 바로가기
            </Button>
          ),
        });
        onDelete(file._id);
      } else {
        message.error('휴지통 이동을 실패했습니다. 다시 시도해 주세요.');
      }
    }
  };

  const handleDownload = async () => {
    if (file._id) {
      try {
        const { presignedUrl } = await generatePresignedUrl(file._id);
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = presignedUrl;
        document.body.appendChild(iframe);
        message.success('파일 다운로드를 시작합니다.');
        setTimeout(() => {
          document.body.removeChild(iframe);
        }, 2000);
      } catch (error) {
        message.error('파일 다운로드에 실패했습니다.');
      }
    }
  };

  const handleEditModalSave = async (newTitle: string, newKeywords?: string[]) => {
    if (file._id) {
      try {
        const success = await updateFile(file._id, {
          title: newTitle,
          keywords: newKeywords || []
        });
        if (success) {
          onEdit(file._id, newTitle, newKeywords || []);
        }
      } catch (error) {
        message.error('수정 중 오류가 발생했습니다.');
      }
    }
    setIsEditModalVisible(false);
  };

  const SummaryContent = () => (
    <Popover
      content={<div style={styles.fullSummary}>{file.summary}</div>}
      title="전체 요약"
      trigger="hover"
      mouseEnterDelay={0.5}
    >
      <div style={styles.summary}>{file.summary}</div>
    </Popover>
  );

  const menu = (
    <Menu>
      <Menu.Item key="edit" onClick={() => setIsEditModalVisible(true)} icon={<EditOutlined />}>
        수정
      </Menu.Item>
      <Menu.Item key="delete" onClick={handleDelete} icon={<DeleteOutlined />}>
        삭제
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Card
        hoverable
        style={styles.card}
        cover={
          <div 
            style={styles.fileIconContainer}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            {getFileIcon(file.fileType)}
            <div style={styles.overlay}>
              <div style={styles.keywords}>
                {displayedKeywords.map((keyword, index) => (
                  <span key={index} style={styles.keyword}>
                    #{keyword}
                  </span>
                ))}
              </div>
            </div>
            <div style={styles.actions}>
              <Dropdown overlay={menu} trigger={['click']}>
                <MoreOutlined 
                  style={styles.icon} 
                  onClick={(e) => e.stopPropagation()} 
                />
              </Dropdown>
            </div>
          </div>
        }
      >
        <div style={styles.iconContainer}>
          <Tooltip title={viewCount === 0 ? '읽지 않음' : `${viewCount}회 조회`}>
            <EyeOutlined style={styles.icon} onClick={handleView} />
            {viewCount > 0 && <span style={{ marginLeft: '5px' }}>{viewCount}</span>}
          </Tooltip>
          <Tooltip title={isBookmarked ? '북마크 해제' : '북마크'}>
            {isBookmarked ? (
              <StarFilled onClick={handleBookmarkToggle} style={{ ...styles.icon, color: '#fadb14' }} />
            ) : (
              <StarOutlined onClick={handleBookmarkToggle} style={styles.icon} />
            )}
          </Tooltip>
          <Tooltip title="다운로드">
            <DownloadOutlined style={styles.icon} onClick={handleDownload} />
          </Tooltip>
        </div>

        <Meta 
          title={
            <div style={{ display: 'flex', alignItems: 'flex-start', gap: '8px', marginBottom: '8px' }}>
              <Badge 
                count="File"
                style={{
                  backgroundColor: '#87d068',
                  fontSize: '12px',
                  padding: '0 8px',
                  height: '22px',
                  lineHeight: '22px',
                  borderRadius: '11px',
                  flexShrink: 0
                }}
              />
              <Popover 
                content={file.title} 
                trigger="hover"
                placement="top"
                mouseEnterDelay={1.5}
              >
                <span style={{ 
                  overflow: 'hidden', 
                  textOverflow: 'ellipsis', 
                  whiteSpace: 'nowrap',
                  cursor: 'pointer'
                }}>
                  {file.title}
                </span>
              </Popover>
            </div>
          }
          description={<SummaryContent />}
        />
      </Card>
      <EditModal
        visible={isEditModalVisible}
        onCancel={() => setIsEditModalVisible(false)}
        onSave={handleEditModalSave}
        initialTitle={file.title}
        initialKeywords={file.keywords || []}
      />
    </>
  );
};

export default FileCard;