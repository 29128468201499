import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { useInView } from 'react-intersection-observer';
import { ObjDoc, isFile, isArticle, isPiece } from '../types/ChildObj';
import { getObjDocs, setDeletedAt } from '../api/ChildObj';
import CategoryCard from '../components/ui/card/CategoryCard';
import CategoryCardSkeleton from '../components/ui/Skeleton/CategoryCardSkeleton';
import FileCard from '../components/ui/card/FileCard';
import QuickClipCard from '../components/ui/card/QuickClipCard';
import { Row, Col, Empty, Select, Badge, Spin, message, notification, Button, Checkbox, Space } from 'antd';
import { SortAscendingOutlined, SortDescendingOutlined } from '@ant-design/icons';
import { saveConfirmedCategories, getConfirmedCategories, saveRecentView } from '../utils/localStorage';
import { downloadFile } from '../utils/downloadFile';
import CategoryMenu from '../components/ui/card/CategoryMenu';
import CategoryWallet from '../components/ui/card/CategoryWallet';

const { Option } = Select;
const ITEMS_PER_PAGE = 20;


const CategoryPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { superCategory, midcategory, subCategory } = useParams<{
    superCategory?: string;
    midcategory?: string;
    subCategory?: string;
  }>();

  const [currentPage, setCurrentPage] = useState(1);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [sortOrder, setSortOrder] = useState<'newest' | 'oldest' | 'viewCount'>('newest');
  const [confirmedCategories, setConfirmedCategories] = useState<string[]>([]);
  const [selectedDomain, setSelectedDomain] = useState<string>('all');
  const [selectedFileType, setSelectedFileType] = useState<string>('all');
  const { ref, inView } = useInView();
  const isArchivePage = location.pathname.includes('/archive');

  // 전체 데이터 가져오기
  const {
    data: totalData,
    isLoading: isTotalLoading,
    isError,
    refetch
  } = useInfiniteQuery<ObjDoc[]>({
    queryKey: ['total-items', isArchivePage],
    queryFn: async ({ pageParam = 1 }) => {
      if (isArchivePage) {
        return getObjDocs('file', 1, 1000);
      } else {
        const [articles, pieces] = await Promise.all([
          getObjDocs('article', 1, 1000),
          getObjDocs('piece', 1, 1000)
        ]);
        return [...articles, ...pieces];
      }
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => undefined, // 한 번에 모든 데이터를 가져오므로 undefined 반환
    staleTime: 5 * 60 * 1000,
  });

   // 도메인 목록 추출
  const getDomains = React.useMemo(() => {
    if (isArchivePage) return []; // 아카이브 페이지에서는 빈 배열 반환
    const allItems = totalData?.pages.flat() ?? [];
    const domains = new Set(allItems.map(item => item.domainName).filter(Boolean));
    return ['all', ...Array.from(domains)];
  }, [totalData, isArchivePage]);

  // 파일 타입 목록 추출
  const getFileTypes = React.useMemo(() => {
    if (!isArchivePage) return []; // 퀵클립 페이지에서는 빈 배열 반환
    const allItems = totalData?.pages.flat() ?? [];
    const fileTypes = new Set(
      allItems
        .map(item => item.fileType)
        .filter((type): type is string => type !== undefined && type !== null)
    );
    return ['all', ...Array.from(fileTypes)];
  }, [totalData, isArchivePage]);

  // 필터링된 전체 아이템
  const filteredItems = React.useMemo(() => {
    const allItems = totalData?.pages.flat() ?? [];
    let filtered = [...allItems];

    // 아카이브 페이지일 때는 fileType 필터링
    if (isArchivePage && selectedFileType !== 'all') {
      filtered = filtered.filter(item => item.fileType === selectedFileType);
    }
    
    // 퀵클립 페이지일 때는 domain 필터링
    if (!isArchivePage && selectedDomain !== 'all') {
      filtered = filtered.filter(item => item.domainName === selectedDomain);
    }

    if (superCategory) {
      filtered = filtered.filter(item => item.supercategory === superCategory);
    }

    if (midcategory) {
      filtered = filtered.filter(item => item.midcategory === midcategory);
    }
  
    if (subCategory) {
      filtered = filtered.filter(item => item.subcategory === subCategory);
    }

    return filtered.sort((a, b) => {
      if (sortOrder === 'newest') {
        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
      } else if (sortOrder === 'oldest') {
        return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
      } else if (sortOrder === 'viewCount') {
        return (b.views || 0) - (a.views || 0);
      }
      return 0;
    });
  }, [totalData, superCategory, midcategory, subCategory,sortOrder, selectedDomain, selectedFileType, isArchivePage]);


  // 현재 페이지에 보여줄 아이템들
  const displayItems = React.useMemo(() => {
    const startIndex = 0;
    const endIndex = currentPage * ITEMS_PER_PAGE;
    return filteredItems.slice(startIndex, endIndex);
  }, [filteredItems, currentPage]);

  useEffect(() => {
    const savedConfirmedCategories = getConfirmedCategories();
    setConfirmedCategories(savedConfirmedCategories);
  }, []);

  useEffect(() => {
    setCurrentPage(1);
  }, [superCategory, midcategory]);

  useEffect(() => {
    if (inView && displayItems.length < filteredItems.length) {
      setCurrentPage(prev => prev + 1);
    }
  }, [inView, displayItems.length, filteredItems.length]);

  const updateUrl = useCallback((newSuperCat?: string, newMidCat?: string, newSubCat?: string) => {
    const baseUrl = isArchivePage ? '/archive' : '/quickclip';
    let newUrl = baseUrl;

    if (newSuperCat) {
      newUrl += `/${encodeURIComponent(newSuperCat)}`;
      if (newMidCat) {
        newUrl += `/${encodeURIComponent(newMidCat)}`;
        if (newSubCat) {
          newUrl += `/${encodeURIComponent(newSubCat)}`;
        }
      }
    }

    navigate(newUrl);
  }, [navigate, isArchivePage]);

  const isNewItem = useCallback((item: ObjDoc) => {
    const currentDate = new Date();
    const itemDate = new Date(item.createdAt);
    return currentDate.toLocaleDateString() === itemDate.toLocaleDateString();
  }, []);

  // const handleCategorySelect = useCallback((level: 'super' | 'mid' | 'sub', category: string, currentSuperCategory?: string) => {
    
  //   if (level === 'super') {
  //     updateUrl(category);
  //   } else if (level === 'mid') {
  //     updateUrl(superCategory || currentSuperCategory, category);
  //   } else if (level === 'sub') {
  //     updateUrl(superCategory, midcategory, category);
  //   }
  // }, [updateUrl, superCategory, midcategory]);
  const handleCategorySelect = useCallback((level: 'super' | 'mid' | 'sub', category: string, currentSuperCategory?: string) => {
    if (level === 'super') {
      updateUrl(category);
    } else if (level === 'mid') {
      updateUrl(superCategory || currentSuperCategory, category);
    } else if (level === 'sub') {
      const parentMidCategory = totalData?.pages[0]?.find(
        item => item.subcategory === category
      )?.midcategory;
  
      if (parentMidCategory) {
        updateUrl(superCategory, parentMidCategory, category);
      }
    }
  }, [updateUrl, superCategory, midcategory, totalData]);

  const updateQueryCache = useCallback((updatedItems: ObjDoc[]) => {
    queryClient.setQueryData(['total-items', isArchivePage], (old: any) => {
      if (!old?.pages) return { pages: [updatedItems], pageParams: [1] };
      return {
        ...old,
        pages: [updatedItems]
      };
    });
  }, [queryClient, isArchivePage]);

  const handleViewCountChange = useCallback((id: string, newCount: number) => {
    const allItems = totalData?.pages.flat() ?? [];
    const updatedItems = allItems.map(item =>
      item._id === id && item.views !== newCount
        ? { ...item, views: newCount }
        : item
    );
    updateQueryCache(updatedItems);
  }, [totalData, updateQueryCache]);

  const handleBookmarkToggle = useCallback((id: string, isBookmarked: boolean) => {
    const allItems = totalData?.pages.flat() ?? [];
    const updatedItems = allItems.map(item =>
      item._id === id ? { ...item, bookmark: isBookmarked } : item
    );
    updateQueryCache(updatedItems);
  }, [totalData, updateQueryCache]);

  const handleDelete = useCallback((id: string) => {
    const allItems = totalData?.pages.flat() ?? [];
    const updatedItems = allItems.filter(item => item._id !== id);
    updateQueryCache(updatedItems);
  }, [totalData, updateQueryCache]);

  const handleEdit = useCallback((id: string, newTitle: string, newKeywords: string[]) => {
    const allItems = totalData?.pages.flat() ?? [];
    const updatedItems = allItems.map(item =>
      item._id === id ? { ...item, title: newTitle, keywords: newKeywords } : item
    );
    updateQueryCache(updatedItems);
  }, [totalData, updateQueryCache]);


  const handleViewItemClick = useCallback((item: ObjDoc) => {
    const viewData = {
      title: item.title,
      createdAt: item.createdAt,
      _class_id: item._class_id,
      supercategory: item.supercategory,
      midcategory: item.midcategory,
      subcategory: item.subcategory || null,
    };
    saveRecentView(viewData);
    
    // 조회수 증가
    const allItems = totalData?.pages.flat() ?? [];
    const updatedItems = allItems.map(i =>
      i._id === item._id ? { ...i, views: (i.views || 0) + 1 } : i
    );
    updateQueryCache(updatedItems);
  }, [totalData, updateQueryCache]);

  const handleDownload = async (id: string, fileName: string) => {
    try {
      await downloadFile(id);
      message.success('파일 다운로드가 시작됩니다.');
    } catch (error) {
      message.error('파일 다운로드에 실패했습니다.');
    }
  };

  const handleSelectAll = (checked: boolean) => {
    setSelectAll(checked);
    if (checked) {
      setSelectedItems(displayItems.map(item => item._id || '').filter(id => id !== ''));
    } else {
      setSelectedItems([]);
    }
  };

  // 개별 선택 핸들러
  const handleSelectItem = (id: string, checked: boolean) => {
    setSelectedItems(prev => {
      const newSelected = checked 
        ? [...prev, id]
        : prev.filter(item => item !== id);
      
      setSelectAll(newSelected.length === displayItems.length);
      return newSelected;
    });
  };

  // 선택된 항목 일괄 삭제
  const handleBulkDelete = async () => {
    try {
      await Promise.all(
        selectedItems.map(id => {
          const itemToDelete = displayItems.find(item => item._id === id);
          return itemToDelete ? setDeletedAt(itemToDelete) : Promise.resolve();
        })
      );

      const allItems = totalData?.pages.flat() ?? [];
      const updatedItems = allItems.filter(item => !selectedItems.includes(item._id || ''));
      updateQueryCache(updatedItems);
      
      setSelectedItems([]);
      setSelectAll(false);

      notification.open({
        message: '항목들을 휴지통으로 이동했습니다.',
        description: '30일 후에 영구삭제 됩니다.',
        placement: 'topRight',
        btn: (
          <Button 
            type="primary" 
            size="small" 
            onClick={() => navigate('/trash')}
            style={{ 
              borderRadius: '20px',
              marginTop: '8px'
            }}
          >
            휴지통 바로가기
          </Button>
        ),
        style: {
          borderRadius: '8px'
        },
        duration: 4.5
      });
    } catch (error) {
      message.error('일괄 삭제 중 오류가 발생했습니다.');
    }
  };

  const renderFilterOptions = () => (
    <Space size="middle">
      {/* 아카이브 페이지일 때는 파일 타입 필터만 표시 */}
      {isArchivePage ? (
        <Select
          value={selectedFileType}
          style={{ width: 150 }}
          onChange={(value: string) => setSelectedFileType(value)}
          placeholder="파일 타입"
        >
          {getFileTypes.map(fileType => (
            <Option key={fileType} value={fileType}>
              {fileType === 'all' ? '전체 파일' : fileType?.toUpperCase() ?? '알 수 없음'}
            </Option>
          ))}
        </Select>
      ) : (
        /* 퀵클립 페이지일 때는 도메인 필터만 표시 */
        <Select
          value={selectedDomain}
          style={{ width: 150 }}
          onChange={(value: string) => setSelectedDomain(value)}
          placeholder="도메인 선택"
        >
          {getDomains.map(domain => (
            <Option key={domain} value={domain}>
              {domain === 'all' ? '전체 도메인' : domain}
            </Option>
          ))}
        </Select>
      )}

      <Select
        value={sortOrder}
        style={{ width: 120 }}
        onChange={(value: 'newest' | 'oldest' | 'viewCount') => setSortOrder(value)}
      >
        <Option value="newest">
          <SortDescendingOutlined /> 최신순
        </Option>
        <Option value="oldest">
          <SortAscendingOutlined /> 오래된순
        </Option>
        <Option value="viewCount">
          <SortDescendingOutlined /> 조회순
        </Option>
      </Select>
    </Space>
  );

  const renderContent = () => {
    if (isTotalLoading) {
      return <CategoryCardSkeleton count={3} />;
    }

    return (
      <>
        {(location.pathname !== '/quickclip' && location.pathname !== '/archive') && (
        // <CategoryCard
        //   items={totalData?.pages[0] ?? []}
        //   superCategory={superCategory}
        //   midCategory={midcategory}
        //   subCategory={subCategory}
        //   onCategorySelect={handleCategorySelect}
        //   confirmedCategories={confirmedCategories}
        //   onConfirmNewItem={(category) => {
        //     setConfirmedCategories(prev => {
        //       const updated = [...prev, category];
        //       saveConfirmedCategories(updated);
        //       return updated;
        //     });
        //   }}
        // />
        <CategoryMenu
        items={totalData?.pages[0] ?? []}
        currentSuperCategory={superCategory ?? ''}
        onCategorySelect={handleCategorySelect}
        confirmedCategories={confirmedCategories}
        onConfirmNewItem={(category) => {
          setConfirmedCategories(prev => {
            const updated = [...prev, category];
            saveConfirmedCategories(updated);
            return updated;
          });
        }}
      />
      // <CategoryWallet
      //   items={totalData?.pages[0] ?? []}
      //   superCategory={superCategory}
      //   midCategory={midcategory}
      //   subCategory={subCategory}
      //   onCategorySelect={handleCategorySelect}
      //   confirmedCategories={confirmedCategories}
      //   onConfirmNewItem={(category) => {
      //     setConfirmedCategories(prev => {
      //       const updated = [...prev, category];
      //       saveConfirmedCategories(updated);
      //       return updated;
      //     });
      //   }}
      // />
      )}

<Row gutter={[16, 16]} style={{ marginBottom: '24px' }} align="middle" justify="space-between">
          <Col xs={24} sm={8}>
            {renderFilterOptions()}
          </Col>

          {displayItems.length > 0 && (
            <Col xs={24} sm={16} style={{ 
              display: 'flex',  
              justifyContent: 'flex-end',
              alignItems: 'center',
              gap: '12px'
            }}>
              <Checkbox
                checked={selectAll}
                onChange={e => handleSelectAll(e.target.checked)}
              >
                전체 선택
              </Checkbox>
              {selectedItems.length > 0 && (
                <Button 
                  type="text"
                  danger 
                  onClick={handleBulkDelete}
                >
                  선택 항목 삭제 ({selectedItems.length})
                </Button>
              )}
            </Col>
          )}
        </Row>

        {displayItems.length === 0 ? (
          <Empty description="게시물이 없습니다. 새로운 콘텐츠를 업로드해보세요!" />
        ) : (
          <Row gutter={[16, 16]}>
            {displayItems.map((item, index) => (
              <Col 
                xs={24} 
                sm={12} 
                md={8} 
                lg={6} 
                key={item._id}
                ref={index === displayItems.length - 5 ? ref : undefined}
              >
                <div style={{ 
                  position: 'relative',
                  cursor: 'pointer'
                }}>
                  <div style={{
                    position: 'absolute',
                    top: '10px',
                    left: '10px',
                    zIndex: 10
                  }}>
                    <Checkbox
                      checked={selectedItems.includes(item._id || '')}
                      onChange={e => handleSelectItem(item._id || '', e.target.checked)}
                      onClick={e => e.stopPropagation()}
                      style={{
                        transform: 'scale(1.3)'
                      }}
                    />
                  </div>
                  <Badge.Ribbon
                    text="New"
                    color="green"
                    placement="start"
                    style={{
                      display: isNewItem(item) ? 'block' : 'none',
                      top: 'auto',
                      bottom: '185px'
                    }}
                  >
                    {isArchivePage ? (
                      isFile(item) && (
                        <FileCard
                          file={item}
                          onViewCountChange={handleViewCountChange}
                          onBookmarkToggle={handleBookmarkToggle}
                          onDelete={handleDelete}
                          onEdit={handleEdit}
                        />
                      )
                    ) : (
                      (isArticle(item) || isPiece(item)) && (
                        <QuickClipCard
                          obj={item}
                          onView={() => handleViewItemClick(item)}
                          onViewCountChange={handleViewCountChange}
                          onBookmarkToggle={handleBookmarkToggle}
                          onDelete={handleDelete}
                          onEdit={handleEdit}
                        />
                      )
                    )}
                  </Badge.Ribbon>
                </div>
              </Col>
            ))}
            {displayItems.length < filteredItems.length && (
              <Col span={24} style={{ textAlign: 'center', padding: '20px' }}>
                <Spin />
              </Col>
            )}
          </Row>
        )}
      </>
    );
  };

  return (
    <div style={{ padding: '24px' }}>
      <h1 style={{ marginBottom: '24px' }}>
        {subCategory || midcategory || superCategory || (isArchivePage ? '아카이브' : '퀵클립')}
      </h1>
      {renderContent()}
      {isError && <div>데이터를 불러오는 중 오류가 발생했습니다.</div>}
    </div>
  );
};

export default CategoryPage;