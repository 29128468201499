import React, { useState } from 'react';
import { Modal, Tabs, Input, Button, Tag, Spin, message, Divider, Row, Col } from 'antd';
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons';

interface EditModalProps {
    visible: boolean;
    onCancel: () => void;
    onSave: (title: string, keywords?: string[]) => Promise<void>; 
    initialTitle: string;
    initialKeywords?: string[];
    titleOnly?: boolean;
}

const EditModal: React.FC<EditModalProps> = ({
   visible,
   onCancel,
   onSave,
   initialTitle,
   initialKeywords = [],
   titleOnly = false,
}) => {
   const [selectedKeywords, setSelectedKeywords] = useState(initialKeywords.slice(0, 5));
   const [remainingKeywords, setRemainingKeywords] = useState(initialKeywords.slice(5));
   const [title, setTitle] = useState(initialTitle);
   const [inputVisible, setInputVisible] = useState(false);
   const [inputValue, setInputValue] = useState('');
   const [loading, setLoading] = useState(false);
   const [maxKeywordsReached, setMaxKeywordsReached] = useState(false);

   const MAX_SELECTED_KEYWORDS = 5;
   const MAX_TOTAL_KEYWORDS = 10;

   const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
       setTitle(e.target.value);
   };

   const tagStyle = {
       borderRadius: '12px',
       padding: '5px 12px',
       fontSize: '14px',
   };

   const handleRemoveSelectedKeyword = (removedKeyword: string) => {
       setSelectedKeywords(prev => prev.filter(keyword => keyword !== removedKeyword));
       setRemainingKeywords(prev => [...prev, removedKeyword]);
   };

   const handleSelectKeyword = (keyword: string) => {
       if (selectedKeywords.length < MAX_SELECTED_KEYWORDS) {
           setRemainingKeywords(prev => prev.filter(k => k !== keyword));
           setSelectedKeywords(prev => [...prev, keyword]);
       } else {
           message.warning(`5개의 키워드를 선택해야 합니다.`);
       }
   };

   const handleRemoveAvailableKeyword = (removedKeyword: string) => {
       setRemainingKeywords(prev => prev.filter(keyword => keyword !== removedKeyword));
   };

   const showInput = () => {
       setInputVisible(true);
   };

   const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
       setInputValue(e.target.value);
   };

   const handleInputConfirm = () => {
       const totalKeywords = selectedKeywords.length + remainingKeywords.length;
       if (inputValue && totalKeywords < MAX_TOTAL_KEYWORDS) {
           setRemainingKeywords([...remainingKeywords, inputValue]);
       } else if (totalKeywords >= MAX_TOTAL_KEYWORDS) {
           setMaxKeywordsReached(true);
       }
       setInputVisible(false);
       setInputValue('');
   };

   const handleSave = async () => {
       if (title.trim() === '') {
           message.error('제목을 입력하세요.');
           return;
       }

       if (!titleOnly && selectedKeywords.length < MAX_SELECTED_KEYWORDS) {
           message.error(`5개의 키워드를 선택해 주세요.`);
           return;
       }

       setLoading(true);
       try {
           if (titleOnly) {
               await onSave(title);
           } else {
               const updatedKeywords = [...selectedKeywords, ...remainingKeywords];
               await onSave(title, updatedKeywords);
           }
       } finally {
           setLoading(false);
       }
   };

   const spinIcon = <LoadingOutlined style={{ fontSize: 16 }} spin />;

   const tabItems = [
       {
           key: '1',
           label: '제목',
           children: (
               <Input
                   value={title}
                   onChange={handleTitleChange}
                   placeholder="제목을 입력하세요"
               />
           ),
       },
       ...(!titleOnly ? [{
           key: '2',
           label: '키워드',
           children: (
               <>
                   <Row justify="space-between">
                       <Col span={11}>
                           <div style={{ marginBottom: 16 }}>
                               <strong>선택된 키워드 (5개 선택):</strong>
                               {selectedKeywords.map(keyword => (
                                   <Tag
                                       key={keyword}
                                       closable
                                       color="blue"
                                       onClose={() => handleRemoveSelectedKeyword(keyword)}
                                       style={{ ...tagStyle, marginBottom: 8 }}
                                   >
                                       {keyword}
                                   </Tag>
                               ))}
                           </div>
                       </Col>
                       <Col span={2}>
                           <Divider type="vertical" style={{ height: '100%' }} />
                       </Col>
                       <Col span={11}>
                           <div style={{ marginBottom: 16 }}>
                               <strong>사용 가능한 키워드:</strong>
                               {remainingKeywords.map(keyword => (
                                   <Tag
                                       key={keyword}
                                       closable
                                       onClose={() => handleRemoveAvailableKeyword(keyword)}
                                       onClick={() => handleSelectKeyword(keyword)}
                                       color="green"
                                       style={{ ...tagStyle, cursor: 'pointer', marginBottom: 8 }}
                                   >
                                       {keyword}
                                   </Tag>
                               ))}
                           </div>
                       </Col>
                   </Row>

                   {inputVisible && (
                       <Input
                           type="text"
                           size="small"
                           style={{ width: '100%', marginBottom: 8 }}
                           value={inputValue}
                           onChange={handleInputChange}
                           onBlur={handleInputConfirm}
                           onPressEnter={handleInputConfirm}
                       />
                   )}
                   {!inputVisible && selectedKeywords.length + remainingKeywords.length < MAX_TOTAL_KEYWORDS && (
                       <Button type="dashed" onClick={showInput} style={{ width: '100%' }}>
                           <PlusOutlined /> 키워드 추가
                       </Button>
                   )}

                   {maxKeywordsReached && (
                       <p style={{ color: 'red', textAlign: 'center', marginTop: 8 }}>
                           최대 10개의 키워드만 추가할 수 있습니다.
                       </p>
                   )}
               </>
           ),
       }] : [])
   ];

   return (
       <Modal
           open={visible}
           title="수정"
           onCancel={onCancel}
           footer={[
               <Button key="cancel" onClick={onCancel} disabled={loading}>
                   취소
               </Button>,
               <Button key="save" type="primary" onClick={handleSave} disabled={loading}>
                   수정 {loading && <Spin indicator={spinIcon} size="small" />}
               </Button>,
           ]}
           maskClosable={false}
           keyboard={true}
       >
           <Tabs items={tabItems} defaultActiveKey="1" />
       </Modal>
   );
};

export default EditModal;