import axios, { AxiosError } from 'axios';
import Cookies from 'js-cookie';
import { message } from 'antd';

// JWT 디코딩 함수
const decodeToken = (token: string) => {
    try {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        return JSON.parse(jsonPayload);
    } catch (error) {
        return null;
    }
};

// 토큰 만료 체크 함수
const isTokenExpiring = (token: string): boolean => {
    const decoded = decodeToken(token);
    if (!decoded || !decoded.exp) return true;
    
    const expiryTime = decoded.exp * 1000; // 초를 밀리초로 변환
    const currentTime = Date.now();
    const timeUntilExpiry = expiryTime - currentTime;
    
    return timeUntilExpiry <= 60000; // 1분 이내로 남았으면 true
};

// Axios 인스턴스 생성
const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    },
});

// 토큰 갱신 관련 상태 관리
let isRefreshing = false;
let refreshSubscribers: ((token: string) => void)[] = [];

// 토큰 갱신이 완료되면 대기 중인 요청들을 처리
const onRefreshed = (token: string) => {
    refreshSubscribers.forEach(callback => callback(token));
    refreshSubscribers = [];
};

// 토큰 갱신 함수
const refreshToken = async () => {
    try {
        const refreshToken = Cookies.get('refreshToken');
        const accessToken = Cookies.get('accessToken');
        const tokenType = Cookies.get('tokenType') || 'Bearer';

        if (!refreshToken) {
            throw new Error('Refresh token is missing');
        }

        // 리프레시 토큰 만료 체크
        if (isTokenExpiring(refreshToken)) {
            message.warning('인증이 곧 만료됩니다. 다시 로그인해 주세요.');
            window.dispatchEvent(new Event('logout'));
            return null;
        }

        const response = await axios.post(
            `${process.env.REACT_APP_API_URL}api/v1/auth/refresh`,
            { accessToken, refreshToken, tokenType },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            }
        );

        const { access_token, refresh_token, token_type } = response.data;
        
        // 새 토큰을 쿠키에 저장
        Cookies.set('accessToken', access_token, { secure: true, sameSite: 'strict' });
        Cookies.set('refreshToken', refresh_token, { secure: true, sameSite: 'strict' });
        Cookies.set('tokenType', token_type, { secure: true, sameSite: 'strict' });

        return access_token;
    } catch (error) {
        window.dispatchEvent(new Event('logout'));
        throw error;
    }
};

// 요청 인터셉터
axiosInstance.interceptors.request.use(
    async (config) => {
        const token = Cookies.get('accessToken');
        
        if (token) {
            if (isTokenExpiring(token)) {
                if (!isRefreshing) {
                    isRefreshing = true;
                    
                    try {
                        const newToken = await refreshToken();
                        isRefreshing = false;
                        if (newToken) {
                            onRefreshed(newToken);
                            config.headers['Authorization'] = `Bearer ${newToken}`;
                        }
                    } catch (error) {
                        isRefreshing = false;
                        throw error;
                    }
                } else {
                    // 토큰 갱신 중이라면 새 토큰을 받을 때까지 대기
                    const newToken = await new Promise<string>((resolve) => {
                        refreshSubscribers.push((token: string) => {
                            resolve(token);
                        });
                    });
                    config.headers['Authorization'] = `Bearer ${newToken}`;
                }
            } else {
                config.headers['Authorization'] = `Bearer ${token}`;
            }
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// 응답 인터셉터
axiosInstance.interceptors.response.use(
    (response) => response,
    async (error: AxiosError) => {
        // 에러 처리 로직이 필요한 경우 여기에 추가
        return Promise.reject(error);
    }
);

export default axiosInstance;