import React, { CSSProperties, useState, useEffect, useRef } from 'react';
import { Layout, Breadcrumb, Avatar, Dropdown } from 'antd';
import { HomeOutlined } from '@ant-design/icons';
import { useLocation, Link } from 'react-router-dom';
import { useUserInfo } from '../../hooks/useUserInfo';
import Search from '../ui/Search/Search';
import UserMenuPopup from '../ui/modal/UserMenuPopup';

interface HeaderProps {
  customStyle?: CSSProperties;
}

const { Header: AntHeader } = Layout;

const Header: React.FC<HeaderProps> = ({ customStyle }) => {
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(false);
  const [isSearchExpanded, setIsSearchExpanded] = useState(true);
  const [availableWidth, setAvailableWidth] = useState<number>(0);
  const headerRef = useRef<HTMLDivElement>(null);
  const breadcrumbRef = useRef<HTMLDivElement>(null);
  const searchRef = useRef<HTMLDivElement>(null);
  const { data: userInfo } = useUserInfo();

  const isHomePage = location.pathname === '/';

  // 알파벳별 배경색 매핑
  const AVATAR_COLORS: { [key: string]: string } = {
    'A': '#FF6B6B', 'B': '#4ECDC4', 'C': '#45B7D1',
    'D': '#96CEB4', 'E': '#FFEEAD', 'F': '#D4A5A5',
    'G': '#9B59B6', 'H': '#3498DB', 'I': '#E67E22',
    'J': '#2ECC71', 'K': '#F1C40F', 'L': '#E74C3C',
    'M': '#1ABC9C', 'N': '#34495E', 'O': '#8E44AD',
    'P': '#F39C12', 'Q': '#16A085', 'R': '#D35400',
    'S': '#27AE60', 'T': '#2980B9', 'U': '#E67E22',
    'V': '#C0392B', 'W': '#7F8C8D', 'X': '#FFA07A',
    'Y': '#32CD32', 'Z': '#B8860B'
  };

  // 이메일에서 첫 글자 추출 후 대문자 변환
  const getInitial = (email: string | undefined): string => {
    if (!email) return '';
    return email.split('@')[0].charAt(0).toUpperCase();
  };

  // 초기값과 배경색 가져오기
  const initial = getInitial(userInfo?.email);
  const avatarBackgroundColor = AVATAR_COLORS[initial] || '#1890ff';

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      if (headerRef.current && searchRef.current) {
        const headerWidth = headerRef.current.offsetWidth;
        const searchWidth = searchRef.current.offsetWidth;
        const padding = 40;
        setAvailableWidth(headerWidth - searchWidth - padding);
      }
    });

    if (headerRef.current) {
      observer.observe(headerRef.current);
    }

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleSearchStateChange = (expanded: boolean) => {
    setIsSearchExpanded(expanded);
  };

  const pathSnippets = location.pathname.split('/').filter((i) => i);

  const decodeURLComponent = (component: string) => {
    try {
      return decodeURIComponent(component.replace(/\+/g, ' '));
    } catch {
      return component;
    }
  };

  const getTruncatedText = (text: string, index: number, totalPaths: number) => {
    const charWidth = 8;
    const availableChars = Math.floor(availableWidth / charWidth / totalPaths);
    const isLastTwo = index >= totalPaths - 2;
    
    if (availableWidth < 200) {
      return text.charAt(0) + '..';
    } else if (availableWidth < 300) {
      return isLastTwo ? (text.length > 3 ? text.slice(0, 3) + '..' : text) : '..';
    } else if (availableWidth < 400) {
      return isLastTwo ? (text.length > 5 ? text.slice(0, 5) + '..' : text) : '..';
    } else {
      if (text.length > availableChars) {
        return text.slice(0, availableChars) + '..';
      }
      return text;
    }
  };

  const breadcrumbItems = [
    <Breadcrumb.Item key="home">
      <Link to="/">
        <HomeOutlined />
      </Link>
    </Breadcrumb.Item>,
  ];

  let currentPath = '';
  pathSnippets.forEach((snippet, index) => {
    currentPath += `/${snippet}`;
    const decodedName = decodeURLComponent(snippet);
    const displayName = getTruncatedText(decodedName, index, pathSnippets.length);

    if (currentPath.startsWith('/quickclip')) {
      const isCurrent = index === pathSnippets.length - 1;
      breadcrumbItems.push(
        <Breadcrumb.Item key={currentPath}>
          {isCurrent ? displayName : <Link to={currentPath}>{displayName}</Link>}
        </Breadcrumb.Item>
      );
    } else {
      breadcrumbItems.push(
        <Breadcrumb.Item key={currentPath}>
          <Link to={currentPath}>{displayName}</Link>
        </Breadcrumb.Item>
      );
    }
  });

  const headerStyle: CSSProperties = {
    background: '#fff',
    padding: '0 20px',
    height: '64px',
    display: 'flex',
    justifyContent: isHomePage ? 'flex-end' : 'space-between',
    alignItems: 'center',
    ...customStyle,
  };

  const breadcrumbContainerStyle: CSSProperties = {
    flex: '1',
    minWidth: 0,
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
  };

  const breadcrumbStyle: CSSProperties = {
    margin: '16px 0',
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  };

  const rightSectionStyle: CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    flexShrink: 0,
  };

  return (
    <AntHeader ref={headerRef} style={headerStyle}>
      {!isHomePage && (
        <div ref={breadcrumbRef} style={breadcrumbContainerStyle}>
          <Breadcrumb style={breadcrumbStyle}>
            {breadcrumbItems}
          </Breadcrumb>
        </div>
      )}
      <div ref={searchRef} style={rightSectionStyle}>
        {!isHomePage && <Search size="small" onExpandChange={handleSearchStateChange} />}
        <Dropdown 
          overlay={<UserMenuPopup />} 
          trigger={['click']}
          placement="bottomRight"
          arrow
        >
          <div style={{ cursor: 'pointer' }}>
            <Avatar
              style={{ 
                backgroundColor: avatarBackgroundColor,
                color: '#ffffff',
                fontWeight: 500
              }}
              size="default"
            >
              {initial}
            </Avatar>
          </div>
        </Dropdown>
      </div>
    </AntHeader>
  );
};

export default Header;