import { encryptData, decryptData } from './cryptoUtils';

const STORAGE_KEY = '_cC';
const RECENT_STORAGE_KEY = '_rV';

interface ConfirmedCategoriesData {
    categories: string[];
    timestamp: number;
}

export interface RecentViewData {
    title: string;
    createdAt: string;
    _class_id: string;
    supercategory: string;
    midcategory: string;
    subcategory?: string | null;
    linkUrl?: string;
}
 

export const saveConfirmedCategories = (categories: string[]) => {
    const data: ConfirmedCategoriesData = {
        categories,
        timestamp: Date.now(),
    };
    const encryptedData = encryptData(data); 
    localStorage.setItem(STORAGE_KEY, encryptedData);
};


export const getConfirmedCategories = (): string[] => {
    const storedData = localStorage.getItem(STORAGE_KEY);
    if (!storedData) return [];

    try {
        const decryptedData = decryptData(storedData);
        const currentTime = new Date();
        const savedTime = new Date(decryptedData.timestamp); 
    
        // 날짜가 다르면 데이터 삭제
        if (savedTime.toDateString() !== currentTime.toDateString()) {
            localStorage.removeItem(STORAGE_KEY);
            return [];
        }
    
        return decryptedData.categories;
    } catch (error) {
        console.error('Failed to decrypt data', error);
        return [];
    }
};

export const saveRecentView = (newView: RecentViewData) => {
    const storedData = localStorage.getItem(RECENT_STORAGE_KEY);
    let recentViews: RecentViewData[] = storedData ? decryptData(storedData) : [];

    // Add new view and limit to 8 items
    recentViews = [newView, ...recentViews.filter(item => item.title !== newView.title)];
    if (recentViews.length > 8) recentViews.pop();

    localStorage.setItem(RECENT_STORAGE_KEY, encryptData(recentViews));
};

export const getRecentViews = (): RecentViewData[] => {
    const storedData = localStorage.getItem(RECENT_STORAGE_KEY);
    return storedData ? decryptData(storedData) : [];
};
