// src/components/KeywordsEditor/index.tsx
import React, { useState, useRef } from 'react';
import { TagOutlined } from '@ant-design/icons';
import { KeywordTag } from './KeywordTag';

interface KeywordsEditorProps {
  keywords: string[];
  onChange: (keywords: string[]) => void;
}

export const KeywordsEditor: React.FC<KeywordsEditorProps> = ({ keywords, onChange }) => {
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);
  const [isEditing, setIsEditing] = useState(false);

  const MAX_KEYWORDS = 10; // 최대 키워드 개수 설정

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const trimmedValue = inputValue.trim();
    
    if (e.key === 'Enter' && trimmedValue) {
      e.preventDefault();
      e.stopPropagation(); // 이벤트 전파 중단

      // 이미 10개 이상이면 추가하지 않음
      if (keywords.length >= MAX_KEYWORDS) {
        setInputValue('');
        return;
      }

      // 중복 체크 및 단일 키워드만 추가
      if (!keywords.includes(trimmedValue)) {
        onChange([...keywords, trimmedValue]);
        setInputValue('');
      }
    } else if (e.key === 'Backspace' && !inputValue && keywords.length > 0) {
      onChange(keywords.slice(0, -1));
    }
  };

  const removeKeyword = (indexToRemove: number) => {
    onChange(keywords.filter((_, index) => index !== indexToRemove));
  };

  // 키워드 개수가 최대에 도달했는지 확인
  const isMaxKeywords = keywords.length >= MAX_KEYWORDS;

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'flex-start',
        gap: '0.5rem',
        flexWrap: 'wrap',
        padding: 0,
        cursor: 'text',
        minHeight: '32px',
        width: '100%',
        border: 'none', // 테두리 제거
      }}
      onClick={() => {
        if (!isMaxKeywords) {
          setIsEditing(true);
          inputRef.current?.focus();
        }
      }}
    >
      <TagOutlined style={{ fontSize: '16px' }} />
      
      <div style={{ display: 'flex', flexWrap: 'wrap', flex: 1, alignItems: 'center',  padding: '4px 0'}}>
        {keywords.map((keyword, index) => (
          <KeywordTag
            key={index}
            keyword={keyword}
            onRemove={() => removeKeyword(index)}
          />
        ))}
        
        {!isMaxKeywords && (
          <input
            ref={inputRef}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={handleKeyDown}
            onBlur={() => setIsEditing(false)}
            placeholder={keywords.length === 0 ? "태그를 입력하세요... (최대 10개)" : ""}
            style={{
              border: 'none',
              outline: 'none',
              background: 'transparent',
              fontSize: '0.9rem',
              padding: '4px',
              flex: 1,
              minWidth: '120px'
            }}
          />
        )}
      </div>
    </div>
  );
};