import React from 'react';
import { Typography, Image } from 'antd';
import { ToolOutlined } from '@ant-design/icons';

const MaintenancePage = () => {
 return (
   <div style={{
     display: 'flex',
     flexDirection: 'column',
     alignItems: 'center',
     justifyContent: 'center',
     minHeight: '100vh',
     backgroundColor: '#f5f5f5',
     padding: '0 16px',
     position: 'relative'
   }}>
     <ToolOutlined style={{ 
       fontSize: 400, 
       color: '#bfbfbf', 
       opacity: 0.2,
       position: 'absolute',
       top: '50%',
       left: '50%',
       transform: 'translate(-50%, -50%)',
       zIndex: 0
     }} />
     
     <div style={{ 
       display: 'flex', 
       flexDirection: 'column',
       alignItems: 'center',
       maxWidth: '600px',
       margin: '0 auto'
     }}>
       <Image 
         src="/images/logo-long.png"
         alt="Ceep Logo"
         preview={false}
         style={{ marginLeft: 42, maxWidth: '200px', position: 'relative', zIndex: 1 }}
       />
       <Typography.Title level={2} style={{ marginBottom: 16, textAlign: 'center', zIndex: 1 }}>
         서비스 점검 중입니다
       </Typography.Title>
       <Typography.Paragraph style={{ fontSize: 18, color: '#595959', textAlign: 'center', marginBottom: 32, zIndex: 1 }}>
         더 나은 서비스를 위해 현재 점검 중입니다.<br />
         빠른 시일 내에 찾아뵙겠습니다.
       </Typography.Paragraph>
     </div>
   </div>
 );
};

export default MaintenancePage;