// import React, { useState, useEffect, useRef, useCallback } from 'react';
// import { AutoComplete, Input } from 'antd';
// import { SearchOutlined, CloseOutlined } from '@ant-design/icons';
// import type { InputRef } from 'antd';
// import { useNavigate } from 'react-router-dom';
// import { searchAPI, searchAutocompleteAPI } from '../../../api/search';
// import debounce from 'lodash/debounce';

// export interface SearchProps {
//     size?: 'small' | 'large';
//     style?: React.CSSProperties;
// }

// interface AutocompleteItem {
//     value: string;
//     label: string;
// }

// const Search: React.FC<SearchProps> = ({ size = 'large', style }) => {
//     const [searchValue, setSearchValue] = useState<string>('');
//     const [autocompleteOptions, setAutocompleteOptions] = useState<AutocompleteItem[]>([]);
//     const [selectedValue, setSelectedValue] = useState<string>('');
//     const [responsiveSize, setResponsiveSize] = useState<'small' | 'large'>(size);
//     const inputRef = useRef<InputRef>(null);
//     const navigate = useNavigate();

//     useEffect(() => {
//         const handleResize = () => {
//             if (window.innerWidth < 600) {
//                 setResponsiveSize('small');
//             } else {
//                 setResponsiveSize(size);
//             }
//         };

//         window.addEventListener('resize', handleResize);
//         handleResize(); // 초기 사이즈 설정
//         return () => window.removeEventListener('resize', handleResize);
//     }, [size]);

//     const handleSearch = useCallback(async (value: string) => {
//         if (value.trim()) {
//             try {
//                 await searchAPI(
//                     value.trim(),
//                     'all',
//                     {
//                         title: true,
//                         keywords: true,
//                         body: true,
//                         summary: true,
//                         page: 1,
//                         size: 10
//                     }
//                 );
//                 navigate(`/search?q=${encodeURIComponent(value.trim())}`);
//             } catch (error) {
//                 // console.error('Search failed:', error);
//             }
//         }
//     }, [navigate]);

//     const fetchAutocomplete = useCallback(debounce(async (value: string) => {
//         if (value.trim()) {
//             try {
//                 const result = await searchAutocompleteAPI(value.trim(), 'all');
//                 setAutocompleteOptions(result.map(item => ({ value: item.title, label: item.title })));
//             } catch (error) {
//                 // console.error('Autocomplete failed:', error);
//             }
//         } else {
//             setAutocompleteOptions([]);
//         }
//     }, 300), []);

//     const handleInputChange = (value: string) => {
//         setSearchValue(value);
//         setSelectedValue('');
//         fetchAutocomplete(value);
//     };

//     const handleSelect = (value: string) => {
//         setSelectedValue(value);
//         setSearchValue(value);
//         handleSearch(value);
//     };

//     const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
//         if (e.key === 'Enter') {
//             handleSearch(selectedValue || searchValue);
//         }
//     };

//     const getInputStyle = useCallback(() => {
//         return { 
//             width: responsiveSize === 'small' ? '300px' : '700px', 
//             borderRadius: responsiveSize === 'small' ? '16px' : '20px',
//             height: responsiveSize === 'small' ? '32px' : '50px',
//             fontSize: responsiveSize === 'small' ? '14px' : '16px',
//             backgroundColor: 'transparent',
//             border: '1px solid #d9d9d9',
//             color: 'var(--text-color)', 
//         };
//     }, [responsiveSize]);

//     return (
//         <AutoComplete
//             popupClassName={`certain-category-search-dropdown ${responsiveSize === 'small' ? 'small' : ''}`}
//             popupMatchSelectWidth={responsiveSize === 'small' ? 300 : 700}
//             style={{ ...(responsiveSize === 'small' ? { width: 300 } : { width: 700 }), ...style }}
//             options={autocompleteOptions}
//             value={searchValue}
//             onSelect={handleSelect}
//             onChange={handleInputChange}
//             onKeyDown={handleKeyDown}
//         >
//             <Input
//                 ref={inputRef}
//                 size={responsiveSize}
//                 placeholder=" 제목이나 키워드를 입력하세요."
//                 style={getInputStyle()}
//                 allowClear={{ 
//                     clearIcon: <CloseOutlined style={{ color: 'rgba(0, 0, 0, 0.45)' }} /> 
//                 }}
//                 suffix={
//                     <SearchOutlined
//                         style={{ 
//                             cursor: 'pointer',
//                             marginLeft: '8px' // 클리어 버튼과의 간격 조정
//                         }}
//                         onClick={() => handleSearch(selectedValue || searchValue)}
//                     />
//                 }
//             />
//         </AutoComplete>
//     );
// };

// export default Search;

import React, { useState, useEffect, useRef, useCallback } from 'react';
import { AutoComplete, Input } from 'antd';
import { SearchOutlined, CloseOutlined } from '@ant-design/icons';
import type { InputRef } from 'antd';
import { useNavigate } from 'react-router-dom';
import { searchAPI, searchAutocompleteAPI } from '../../../api/search';
import debounce from 'lodash/debounce';

export interface SearchProps {
    size?: 'small' | 'large';
    style?: React.CSSProperties;
    onExpandChange?: (expanded: boolean) => void;
}

interface AutocompleteItem {
    value: string;
    label: string;
}

const Search: React.FC<SearchProps> = ({ size = 'large', style, onExpandChange }) => {
    const [searchValue, setSearchValue] = useState<string>('');
    const [autocompleteOptions, setAutocompleteOptions] = useState<AutocompleteItem[]>([]);
    const [selectedValue, setSelectedValue] = useState<string>('');
    const [responsiveSize, setResponsiveSize] = useState<'small' | 'large'>(size);
    const [isExpanded, setIsExpanded] = useState(true);
    const inputRef = useRef<InputRef>(null);
    const navigate = useNavigate();
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleResize = () => {
            if (size === 'small') {
                // small 사이즈일 때만 모바일에서 아이콘으로 변경
                if (window.innerWidth < 600) {
                    setResponsiveSize('small');
                    setIsExpanded(false);
                } else {
                    setResponsiveSize('small');
                    setIsExpanded(true);
                }
            } else {
                // large 사이즈는 항상 expanded 상태 유지
                setResponsiveSize('large');
                setIsExpanded(true);
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, [size]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
                if (size === 'small' && window.innerWidth < 600) {
                    setIsExpanded(false);
                }
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [size]);

    useEffect(() => {
        onExpandChange?.(isExpanded);
    }, [isExpanded, onExpandChange]);

    const handleSearch = useCallback(async (value: string) => {
        if (value.trim()) {
            try {
                await searchAPI(
                    value.trim(),
                    'all',
                    {
                        title: true,
                        keywords: true,
                        body: true,
                        summary: true,
                        page: 1,
                        size: 10
                    }
                );
                navigate(`/search?q=${encodeURIComponent(value.trim())}`);
            } catch (error) {
                // console.error('Search failed:', error);
            }
        }
    }, [navigate]);

    const fetchAutocomplete = useCallback(debounce(async (value: string) => {
        if (value.trim()) {
            try {
                const result = await searchAutocompleteAPI(value.trim(), 'all');
                setAutocompleteOptions(result.map(item => ({ value: item.title, label: item.title })));
            } catch (error) {
                // console.error('Autocomplete failed:', error);
            }
        } else {
            setAutocompleteOptions([]);
        }
    }, 300), []);

    const handleInputChange = (value: string) => {
        setSearchValue(value);
        setSelectedValue('');
        fetchAutocomplete(value);
    };

    const handleSelect = (value: string) => {
        setSelectedValue(value);
        setSearchValue(value);
        handleSearch(value);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            handleSearch(selectedValue || searchValue);
        }
    };

    const getInputStyle = useCallback(() => {
        const baseStyle = {
            transition: 'all 0.3s ease',
            backgroundColor: 'transparent',
            border: '1px solid #d9d9d9',
            color: 'var(--text-color)',
        };

        if (responsiveSize === 'small') {
            return {
                ...baseStyle,
                borderRadius: '16px',
                height: '32px',
                fontSize: '14px',
                width: window.innerWidth < 600 ? (isExpanded ? '200px' : '32px') : '300px',
                padding: window.innerWidth < 600 && !isExpanded ? '0' : undefined,
                border: window.innerWidth < 600 && !isExpanded ? 'none' : '1px solid #d9d9d9',
            };
        } else {
            // large 사이즈는 반응형으로 width만 조절
            return {
                ...baseStyle,
                borderRadius: '20px',
                height: '50px',
                fontSize: '16px',
                width: window.innerWidth < 768 ? '100%' : '700px',
                minWidth: '200px', // 최소 너비 설정
            };
        }
    }, [responsiveSize, isExpanded]);

    const handleSearchIconClick = () => {
        if (size === 'small' && window.innerWidth < 600 && !isExpanded) {
            setIsExpanded(true);
            setTimeout(() => inputRef.current?.focus(), 300);
        } else {
            handleSearch(selectedValue || searchValue);
        }
    };

    // small 사이즈이고 축소된 상태일 때는 아이콘만 표시
    if (size === 'small' && window.innerWidth < 600 && !isExpanded) {
        return (
            <div ref={containerRef} style={{ display: 'flex', alignItems: 'center' }}>
                <SearchOutlined
                    style={{
                        fontSize: '18px',
                        cursor: 'pointer',
                        padding: '7px',
                    }}
                    onClick={handleSearchIconClick}
                />
            </div>
        );
    }

    return (
        <div ref={containerRef} style={{ display: 'flex', alignItems: 'center', width: size === 'large' ? '100%' : 'auto' }}>
            <AutoComplete
                popupClassName={`certain-category-search-dropdown ${responsiveSize === 'small' ? 'small' : ''}`}
                popupMatchSelectWidth={true}
                style={{ ...style, width: size === 'large' ? '100%' : 'auto' }}
                options={autocompleteOptions}
                value={searchValue}
                onSelect={handleSelect}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
            >
                <Input
                    ref={inputRef}
                    size={responsiveSize}
                    placeholder=" 제목이나 키워드를 입력하세요."
                    style={getInputStyle()}
                    allowClear={{
                        clearIcon: <CloseOutlined style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                    }}
                    suffix={
                        <SearchOutlined
                            style={{
                                cursor: 'pointer',
                                marginLeft: '8px'
                            }}
                            onClick={handleSearchIconClick}
                        />
                    }
                />
            </AutoComplete>
        </div>
    );
};

export default Search;