import React, { useState, useEffect } from 'react';
import { Table, Button, message, Popconfirm } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import {  
  restoreChildObj, 
  getFromBin 
} from '../api/ChildObj';
import { articleDelete } from '../api/Article';
import { pieceDelete } from '../api/piece';
import { fileDelete } from '../api/File';
import { tangleDelete } from '../api/Tangle';

import { ObjDoc, getObjectType } from '../types/ChildObj';

const STORAGE_PERIOD = 30; // 휴지통 보관 기간 (일)

const BinPage: React.FC = () => {
  const [objects, setObjects] = useState<ObjDoc[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0
  });

  useEffect(() => {
    fetchObjects(pagination.current, pagination.pageSize);
  }, []);

  const fetchObjects = async (page: number, pageSize: number) => {
    setLoading(true);
    try {
      const data = await getFromBin(page, pageSize);
      setObjects(data.items);
      setPagination({
        ...pagination,
        current: page,
        total: data.total
      });
    } catch (error) {
      // console.error('Error fetching objects:', error);
      message.error('휴지통 데이터를 불러오는 데 실패했습니다.');
    } finally {
      setLoading(false);
    }
  };

  const handleTableChange = (newPagination: any) => {
    fetchObjects(newPagination.current, newPagination.pageSize);
  };

  const handleDelete = async () => {
    setLoading(true);
    try {
      for (let key of selectedRowKeys) {
        const obj = objects.find(o => o._id === key);
        if (obj) {
          const objType = getObjectType(obj);
          if (objType === 'article') {
            await articleDelete(obj);
          } else if (objType === 'file') {
            await fileDelete(obj);
          } else if (objType === 'tangle') {
            await tangleDelete(obj);
          }else if (objType === 'piece') {
            await pieceDelete(obj);
          }
        }
      }
      message.success('선택한 항목들이 영구 삭제되었습니다.');
      fetchObjects(pagination.current, pagination.pageSize);
      setSelectedRowKeys([]);
    } catch (error) {
      // console.error('Error deleting objects:', error);
      message.error('삭제 중 오류가 발생했습니다.');
    } finally {
      setLoading(false);
    }
  };

  const handleRestore = async () => {
    setLoading(true);
    try {
      for (let key of selectedRowKeys) {
        const obj = objects.find(o => o._id === key);
        if (obj) {
          await restoreChildObj(obj);
        }
      }
      message.success('선택한 항목들이 복구되었습니다.');
      fetchObjects(pagination.current, pagination.pageSize);
      setSelectedRowKeys([]);
    } catch (error) {
      // console.error('Error restoring objects:', error);
      message.error('복구 중 오류가 발생했습니다.');
    } finally {
      setLoading(false);
    }
  };

  const columns: ColumnsType<ObjDoc> = [
    {
      title: '제목',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: '타입',
      key: 'type',
      render: (_, record) => getObjectType(record),
    },
    {
      title: '삭제일자',
      dataIndex: 'deletedAt',
      key: 'deletedAt',
      render: (date) => new Date(date).toLocaleDateString(),
    },
    {
      title: '남은 보관일',
      key: 'remainingDays',
      render: (_, record) => {
        const deletedDate = new Date(record.deletedAt || '');
        const now = new Date();
        const diffTime = Math.abs(deletedDate.getTime() - now.getTime());
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        const remainingDays = STORAGE_PERIOD - diffDays;
        
        if (remainingDays <= 0) {
          return '곧 삭제됨';
        } else {
          return `${remainingDays}일 남음`;
        }
      },
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys: React.Key[]) => {
      setSelectedRowKeys(newSelectedRowKeys);
    },
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
    ],
  };

  const hasSelected = selectedRowKeys.length > 0;
  const hasMultipleSelected = selectedRowKeys.length > 1;

  return (
    <div style={{ padding: 24 }}>
      <h1 style={{ marginBottom: 16 }}>휴지통</h1>
      <div style={{ marginBottom: 16 }}>
        <Popconfirm
          title="선택한 항목을 영구 삭제하시겠습니까?"
          onConfirm={handleDelete}
          okText="예"
          cancelText="아니오"
        >
          <Button type="primary" danger disabled={!hasSelected} loading={loading}>
            영구 삭제
          </Button>
        </Popconfirm>
        <Button 
          style={{ marginLeft: 8 }}
          onClick={handleRestore} 
          disabled={!hasSelected} 
          loading={loading}
        >
          복구
        </Button>
        {hasMultipleSelected && (
          <Button 
            type='text' 
            style={{ marginLeft: 8 }}
            onClick={() => setSelectedRowKeys([])} 
          >
            전체 취소
          </Button>
        )}
        <span style={{ marginLeft: 8 }}>
          {hasSelected ? `${selectedRowKeys.length}개 선택됨` : ''}
        </span>
      </div>
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={objects}
        rowKey="_id"
        pagination={pagination}
        loading={loading}
        onChange={handleTableChange}
      />
    </div>
  );
};

export default BinPage;