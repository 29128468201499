// src/components/KeywordsEditor/KeywordTag.tsx
import React from 'react';

interface KeywordTagProps {
  keyword: string;
  onRemove: () => void;
}

export const KeywordTag: React.FC<KeywordTagProps> = ({ keyword, onRemove }) => (
  <span 
    style={{
      display: 'inline-flex',
      alignItems: 'center',
      backgroundColor: '#f3f4f6',
      padding: '2px 12px', // 패딩 좌우 여백 증가
      borderRadius: '15px', // 더 둥글게 변경
      margin: '0 4px 4px 0',
      fontSize: '0.9rem',
      color: '#374151',
      cursor: 'default'
    }}
  >
    {keyword}
    <span
      onClick={onRemove}
      style={{
        marginLeft: '6px',
        cursor: 'pointer',
        opacity: 0.6,
        fontSize: '0.8rem',
        fontWeight: 'bold',
        padding: '0 2px'
      }}
      onMouseOver={e => e.currentTarget.style.opacity = '1'}
      onMouseOut={e => e.currentTarget.style.opacity = '0.6'}
    >
      ×
    </span>
  </span>
);