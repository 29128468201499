import axiosInstance from '../utils/axiosInstance';
import { UserInfo, UpdateUserDetailRequest, HasSeenDataModalResponse  } from '../types/user';


export const getUserInfo = async (): Promise<UserInfo> => {
    try {
        const response = await axiosInstance.get<UserInfo>('/api/v1/user/info');
        return response.data;
    } catch (error) {
        // console.error('Error fetching user info:', error);
        throw error;
    }
};


// 사용자 상세 정보 업데이트 (이메일 광고 동의, 데이터 공유 동의)
export const updateUserDetail = async (data: UpdateUserDetailRequest): Promise<void> => {
    try {
        await axiosInstance.patch('/api/v1/user/detail', data);
    } catch (error) {
        throw error;
    }
};

// 회원탈퇴
export const unsubscribe = async (): Promise<void> => {
    try {
        await axiosInstance.delete('/api/v1/user');
    } catch (error) {
        throw error;
    }
};