import { TanglePiece } from "./Tangle";

export type ObjType = 'article' | 'piece' | 'file' | 'all' | 'quickclip' | 'tangle';


export interface ObjDoc {
  _id: string | null;
  createdAt: string;
  updatedAt: string;
  _class_id: string;
  userId: string;
  title: string;
  summary: string | null;
  keywords: string[] | null;
  thumbnail: string | null;
  supercategory: string;
  midcategory: string;
  subcategory: string | null;
  proposedCategories?: {
    categories: {
      name: string;
      confidence: number;
      severity?: number | null;
    }[];
  };
  views: number;
  bookmark: boolean;
  pinned: boolean;
  usage: {
    completion_tokens: number;
    prompt_tokens: number;
    total_tokens: number;
    completion_tokens_details: object | null;
  };
  deletedAt: string | null;
  type?: ObjType; // 추가
  domainName?: string;
  fileType?: string;
}

export interface Article extends ObjDoc {
  linkUrl: string;
  domainName: string;
}
export interface Piece extends ObjDoc {
  linkUrl: string;
  domainName: string;
  body: string;
}

export interface File extends ObjDoc {
  fileType: string;
  fileKey: string;
}

export interface Tangle extends ObjDoc {
  pieces: TanglePiece[];
  body: string;
  default: boolean;
}

export const getObjectType = (item: ObjDoc): ObjType => {
  if ('fileType' in item && 'fileKey' in item) return 'file';
  if ('pieces' in item) return 'tangle';
  if ('body' in item) return 'piece';
  return 'article';
};

export const isArticle = (item: ObjDoc): item is Article => 
  !('body' in item) && !('fileType' in item);

export const isPiece = (item: ObjDoc): item is Piece => 
  'body' in item;

export const isFile = (item: ObjDoc): item is File => 
  'fileType' in item && 'fileKey' in item;