import React, { useState, useRef } from 'react';
import { Modal, Input, Button, Progress, notification } from 'antd';
import { 
  LinkOutlined, 
  CloudUploadOutlined, 
  CloseOutlined,
  CheckCircleOutlined,
  LoadingOutlined,
  WarningOutlined
} from '@ant-design/icons';
import type { UploadFile } from 'antd/es/upload/interface';
import styled from 'styled-components';
import { uploadArticle } from '../../../api/Article';
import { uploadFile } from '../../../api/File';

// const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB

type UploadStatus = 'queued' | 'loading' | 'uploading' | 'done' | 'error';

const ALLOWED_FILE_TYPES = [
  'pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx',
  'txt', 'hwp', 'hwpx', 'hwpml'
];

interface UploadModalProps {
  open: boolean;
  onClose: () => void;
  onUpload: (newUpload: any) => void;
}

interface UrlItem {
  url: string;
  status: 'queued' | 'loading' | 'done' | 'error';
  progress: number;
}

interface ExtendedUploadFile {
  uid: string;
  name: string;
  progress?: number;
  status?: 'queued' | 'uploading' | 'done' | 'error';
}

// Styled components
const ModalContent = styled.div`
  padding: 20px;
`;

const UploadArea = styled.div`
  margin-bottom: 24px;
`;

const DropZone = styled.div<{ isDragging: boolean }>`
  border: 2px dashed ${props => props.isDragging ? '#1890ff' : '#d9d9d9'};
  border-radius: 8px;
  padding: 40px 20px;
  text-align: center;
  background: ${props => props.isDragging ? 'rgba(24, 144, 255, 0.05)' : '#fafafa'};
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    border-color: #1890ff;
    background: rgba(24, 144, 255, 0.05);
  }
`;

const UploadIcon = styled(CloudUploadOutlined)`
  font-size: 48px;
  color: #1890ff;
  margin-bottom: 16px;
`;

const FileInfo = styled.div`
  margin-top: 8px;
  color: #666;
  font-size: 13px;
`;

const WarningText = styled.div`
  color: #ff4d4f;
  font-size: 13px;
  margin-top: 4px;
`;

const UrlSection = styled.div`
  margin: 24px 0;
`;

const UrlInputWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

const ProgressList = styled.div`
  margin-top: 24px;
  max-height: 300px; 
  overflow-y: auto; 
  padding-right: 8px; 
  &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
`;

const ProgressItem = styled.div`
  background: #fafafa;
  padding: 12px 16px;
  border-radius: 4px;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const ProgressHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

const FileName = styled.span`
  font-weight: 500;
`;

const NoDataText = styled.div`
  text-align: center;
  padding: 32px;
  color: #999;
`;

const StatusIcon = styled.span<{ status: UploadStatus }>`
  color: ${props => {
    switch (props.status) {
      case 'done': return '#52c41a';
      case 'error': return '#ff4d4f';
      case 'uploading':
      case 'loading': return '#1890ff';
      default: return '#8c8c8c';
    }
  }};
`;

const UploadModal: React.FC<UploadModalProps> = ({ open, onClose, onUpload }) => {
  const [files, setFiles] = useState<ExtendedUploadFile[]>([]);
  const [urls, setUrls] = useState<UrlItem[]>([]);
  const [url, setUrl] = useState('');
  const [isDragging, setIsDragging] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleDragEnter = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = async (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(false);
    
    const droppedFiles = Array.from(e.dataTransfer.files);
    await processFiles(droppedFiles);
  };

  const processFiles = async (fileList: File[]) => {
    const validFiles = fileList.filter(file => {
      const extension = file.name.split('.').pop()?.toLowerCase();
      const isValidType = ALLOWED_FILE_TYPES.includes(extension || '');
      
      if (!isValidType) {
        notification.error({
          message: '지원되지 않는 형식',
          description: `${file.name}은 지원되지 않는 파일 형식입니다.`
        });
      }
      
      return isValidType;
    });
  
    // 모든 파일을 먼저 queued 상태로 추가
    const newFiles: ExtendedUploadFile[] = validFiles.map(file => ({
      uid: Date.now().toString() + Math.random(),
      name: file.name,
      status: 'queued',
      progress: 0
    }));
    
    setFiles(prev => [...prev, ...newFiles]);
  
    // 파일 순차 처리
    for (let i = 0; i < validFiles.length; i++) {
      const file = validFiles[i];
      const currentFile = newFiles[i];
      
      try {
        setIsProcessing(true);
        
        // 현재 파일 상태를 uploading으로 변경
        setFiles(prev => 
          prev.map(f => 
            f.uid === currentFile.uid 
              ? { ...f, status: 'uploading' }
              : f
          )
        );
  
        // Progress simulation
        for (let progress = 0; progress <= 90; progress += 10) {
          await new Promise(resolve => setTimeout(resolve, 200));
          setFiles(prev => 
            prev.map(f => 
              f.uid === currentFile.uid ? { ...f, progress } : f
            )
          );
        }
  
        const response = await uploadFile(file);
          
        setFiles(prev => 
          prev.map(f => 
            f.uid === currentFile.uid
              ? { ...f, status: 'done', progress: 100 } 
              : f
          )
        );
  
        onUpload({
          type: 'file',
          title: response.title,
          date: new Date().toISOString().split('T')[0]
        });
  
        notification.success({
          message: '업로드 성공',
          description: `${file.name} 파일이 성공적으로 업로드되었습니다.`
        });
  
      } catch (error) {
        setFiles(prev => 
          prev.map(f => 
            f.uid === currentFile.uid
              ? { ...f, status: 'error', progress: 100 } 
              : f
          )
        );
          
        notification.error({
          message: '업로드 실패',
          description: `${file.name} 파일 업로드에 실패했습니다.`
        });
      }
    }
    setIsProcessing(false);
  };

  const handleUrlSubmit = async () => {
    if (url) {
      if (urls.some(item => item.url === url)) {
        notification.warning({
          message: '중복된 URL',
          description: '이미 추가된 URL입니다.'
        });
        return;
      }

      const newUrlItem = { 
        url, 
        status: 'queued' as const, 
        progress: 0 
      };

      setUrls(prev => [...prev, newUrlItem]);
      setUrl('');

      if (!isProcessing) {
        setIsProcessing(true);
        try {
          setUrls(prev =>
            prev.map(item =>
              item.url === newUrlItem.url 
                ? { ...item, status: 'loading', progress: 0 } 
                : item
            )
          );

          // Progress simulation
          for (let i = 0; i <= 90; i += 10) {
            await new Promise(resolve => setTimeout(resolve, 200));
            setUrls(prev =>
              prev.map(item =>
                item.url === newUrlItem.url 
                  ? { ...item, progress: i } 
                  : item
              )
            );
          }

          const article = await uploadArticle(newUrlItem.url);
          
          setUrls(prev =>
            prev.map(item =>
              item.url === newUrlItem.url 
                ? { ...item, status: 'done', progress: 100 } 
                : item
            )
          );

          onUpload({
            type: 'link',
            title: article.title || newUrlItem.url,
            date: new Date().toISOString().split('T')[0],
          });

          notification.success({
            message: '업로드 성공',
            description: '성공적으로 분석 완료했습니다.'
          });

        } catch (error) {
          setUrls(prev =>
            prev.map(item =>
              item.url === newUrlItem.url 
                ? { ...item, status: 'error', progress: 100 } 
                : item
            )
          );

          notification.error({
            message: '업로드 실패',
            description: '이미 존재하거나 분석할 수 없는 정보입니다.'
          });
        } finally {
          setIsProcessing(false);
        }
      }
    }
  };

  const getStatusIcon = (status: UploadStatus) => {
    switch (status) {
      case 'done':
        return <CheckCircleOutlined />;
      case 'error':
        return <WarningOutlined />;
      case 'uploading':
      case 'loading':
        return <LoadingOutlined />;
      default:
        return <CloudUploadOutlined />;
    }
  };

  const handleClose = () => {
    setUrls([]);
    setFiles([]);
    setUrl('');
    setIsProcessing(false);
    onClose();
  };

  return (
    <Modal
      title="파일, 링크를 업로드하세요. Ceep가 분류합니다."
      open={open}
      onCancel={handleClose}
      width={600}
      footer={null}
    >
      <ModalContent>
        <UploadArea>
          <DropZone
            isDragging={isDragging}
            onDragEnter={handleDragEnter}
            onDragOver={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            onClick={() => fileInputRef.current?.click()}
          >
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: 'none' }}
              multiple
              onChange={(e) => {
                if (e.target.files) {
                  processFiles(Array.from(e.target.files));
                }
              }}
            />
            <UploadIcon />
            <div>Click or drag files to this area to upload</div>
            {/* <FileInfo>업로드 파일은 5MB를 초과할 수 없습니다.</FileInfo> */}
            <FileInfo>허용된 파일 형식: PDF, Word, Excel, PowerPoint, txt, hwp</FileInfo>
            <WarningText>(이미지로 된 PDF는 분석이 제한되므로 텍스트 기반 PDF를 권장합니다)</WarningText>
          </DropZone>
        </UploadArea>

        <UrlSection>
          <UrlInputWrapper>
            <Input
              placeholder="분석할 URL을 입력하세요"
              prefix={<LinkOutlined />}
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              onPressEnter={handleUrlSubmit}
              disabled={isProcessing}
            />
            <Button type="primary" onClick={handleUrlSubmit} disabled={isProcessing}>
              Add URL
            </Button>
          </UrlInputWrapper>
        </UrlSection>

        <ProgressList>
          {[...files, ...urls].map((item) => (
            <ProgressItem key={'url' in item ? item.url : item.uid}>
              <ProgressHeader>
                <FileName>{'url' in item ? item.url : item.name}</FileName>
                {item.status === 'error' && (
                  <Button 
                    type="text" 
                    icon={<CloseOutlined />} 
                    onClick={() => {
                      if ('url' in item) {
                        setUrls(prev => prev.filter(u => u.url !== item.url));
                      } else {
                        setFiles(prev => prev.filter(f => f.uid !== item.uid));
                      }
                    }}
                  />
                )}
              </ProgressHeader>
              <Progress 
                percent={item.progress} 
                size="small" 
                status={item.status === 'error' ? 'exception' : undefined}
              />
            </ProgressItem>
          ))}
          {[...files, ...urls].length === 0 && (
            <NoDataText>
              No data
            </NoDataText>
          )}
        </ProgressList>
      </ModalContent>
    </Modal>
  );
};

export default UploadModal;