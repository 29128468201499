import React, { useState } from 'react';
import { Modal, Typography, Checkbox, Space, message } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import { useUserInfo, useUpdateUserDetail } from '../../../hooks/useUserInfo';

const { Title, Text, Paragraph } = Typography;

const DataSharingModal: React.FC = () => {
    const { data: userInfo } = useUserInfo();
    const { mutate: updateUserDetail } = useUpdateUserDetail();
    const [checked, setChecked] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleOk = async () => {
        try {
            setIsSubmitting(true);
            
            updateUserDetail({
                dataSharing: checked,
                emailAdvertising: userInfo?.emailAdvertising || false
            });
            
        } catch (error) {
            message.error('설정 업데이트에 실패했습니다.');
        } finally {
            setIsSubmitting(false);
        }
    };

    // userInfo가 없거나, dataSharing이 이미 boolean 값으로 설정되어 있으면 모달을 보여주지 않음
    if (!userInfo || typeof userInfo.dataSharing === 'boolean') {
        return null;
    }

    return (
        <Modal
            open={true}
            width={520}
            centered
            footer={[
                <button
                    key="submit"
                    onClick={handleOk}
                    disabled={isSubmitting}
                    style={{
                        width: '100%',
                        padding: '8px 16px',
                        borderRadius: '6px',
                        border: '1px solid',
                        cursor: isSubmitting ? 'not-allowed' : 'pointer',
                        fontSize: '14px',
                        opacity: isSubmitting ? 0.5 : 1,
                        ...(checked ? {
                            background: '#1677ff',
                            borderColor: '#1677ff',
                            color: 'white',
                        } : {
                            background: 'white',
                            borderColor: '#d9d9d9',
                            color: '#8c8c8c',
                        })
                    }}
                >
                    {checked ? "동의하고 시작하기" : "나중에 하기"}
                </button>
            ]}
            closable={false}
            maskClosable={false}
            cancelButtonProps={{ style: { display: 'none' } }}
        >
            <div style={{ display: 'flex', alignItems: 'center', gap: '12px', marginBottom: '24px' }}>
                <img
                    src="/icon-48 (2).png"
                    alt="Ceep Logo"
                    style={{ width: '48px', height: '48px' }}
                />
                <div>
                    <Title level={4} style={{ margin: 0 }}>데이터 접근 권한</Title>
                    <Text type="secondary">Ceep AI 기능 사용을 위한 동의</Text>
                </div>
            </div>

            <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                <div style={{ 
                    background: '#f0f5ff', 
                    padding: '12px 16px', 
                    borderRadius: '8px'
                }}>
                    <Text style={{ color: '#1677ff' }}>
                        이용자는 'Ceep'이 이용자가 저장한 데이터에 접근할 수 있도록 허락해야
                        Ceep AI 기능을 이용할 수 있습니다.
                    </Text>
                </div>

                <Paragraph>
                    'Ceep'은 기본적으로 사용자의 데이터를 수집하지 않습니다.
                    다만, AI 서비스 개선을 위해 다음과 같은 데이터를 활용할 수 있습니다:
                </Paragraph>

                <div style={{ 
                    background: '#f5f5f5', 
                    padding: '16px', 
                    borderRadius: '8px' 
                }}>
                    <Space direction="vertical" size="small">
                        <Text>• 서비스 사용 패턴 분석</Text>
                        <Text>• AI 모델 성능 개선</Text>
                        <Text>• 오류 및 버그 수정</Text>
                    </Space>
                </div>

                <div style={{ 
                    border: '1px solid #d9d9d9', 
                    padding: '12px 16px', 
                    borderRadius: '8px',
                    cursor: 'pointer'
                }}>
                    <Checkbox
                        checked={checked}
                        onChange={(e) => setChecked(e.target.checked)}
                    >
                        <Text>Ceep AI의 서비스를 개선하기 위해 Ceep에 데이터 공유</Text>
                    </Checkbox>
                </div>

                <div style={{ 
                    background: '#f5f5f5', 
                    padding: '12px 16px', 
                    borderRadius: '8px',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px'
                }}>
                    <SettingOutlined style={{ color: '#8c8c8c' }} />
                    <Text type="secondary" style={{ fontSize: '13px' }}>
                        데이터 접근 권한은 설정에서 언제든지 변경하실 수 있습니다.
                    </Text>
                </div>

                <div style={{ marginTop: '8px' }}>
                    <Text type="secondary" italic style={{ fontSize: '12px' }}>
                        * 개인을 식별할 수 있는 정보는 수집하지 않으며, 수집된 데이터는 서비스 개선 목적으로만 사용됩니다.
                    </Text>
                </div>
            </Space>
        </Modal>
    );
};

export default DataSharingModal;