import React, { useState, useEffect, CSSProperties } from 'react';
import { Layout, Menu, Button } from 'antd';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { 
  UploadOutlined, 
  StarOutlined, 
  PaperClipOutlined, 
  FolderOutlined, 
  DeleteOutlined, 
  MenuFoldOutlined, 
  MenuUnfoldOutlined,
  BlockOutlined  
} from '@ant-design/icons';
import UploadModal from '../ui/modal/UploadModal';
import { useQuery } from '@tanstack/react-query';
import { getObjDocs } from '../../api/ChildObj';

const { Sider } = Layout;

interface SidebarProps {
  collapsed: boolean;
  toggleCollapse: () => void;
  onUpload?: (newUpload: any) => void;
  customStyle?: CSSProperties;
}

const Sidebar: React.FC<SidebarProps> = ({ collapsed, toggleCollapse, onUpload, customStyle }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [sidebarHeight, setSidebarHeight] = useState(window.innerHeight);
  const [selectedKey, setSelectedKey] = useState('');
  const [openKeys, setOpenKeys] = useState<string[]>([]);
  const navigate = useNavigate();
  const location = useLocation();

  // Fetch data for QuickClip and Archive categories
  const { data: quickclipData } = useQuery({
    queryKey: ['quickclip-categories'],
    queryFn: async () => {
      const [articles, pieces] = await Promise.all([
        getObjDocs('article', 1, 1000),
        getObjDocs('piece', 1, 1000)
      ]);
      return [...articles, ...pieces];
    },
    staleTime: 5 * 60 * 1000,
  });

  const { data: archiveData } = useQuery({
    queryKey: ['archive-categories'],
    queryFn: async () => {
      return getObjDocs('file', 1, 1000);
    },
    staleTime: 5 * 60 * 1000,
  });

  // Extract unique supercategories from data
  const getUniqueCategories = (data: any[] = []) => {
    return Array.from(new Set(data.map(item => item.supercategory))).filter(Boolean);
  };

  const quickclipCategories = getUniqueCategories(quickclipData);
  const archiveCategories = getUniqueCategories(archiveData);

  useEffect(() => {
    const handleResize = () => setSidebarHeight(window.innerHeight);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const path = location.pathname.split('/')[1];
    setSelectedKey(path || 'home');
  }, [location]);

  const openModal = () => {
    setIsModalVisible(true);
    setSelectedKey('upload');
  };
  
  const closeModal = () => setIsModalVisible(false);

  const handleMenuClick = (path: string) => {
    navigate(path);
  };

  // Main menu items with dynamic supercategories as children and onTitleClick for parent items
  const mainMenuItems = [
    {
      key: 'bookmark',
      icon: <StarOutlined />,
      label: '북마크',
      onClick: () => handleMenuClick('/bookmark'),
    },
    {
      key: 'quickclip',
      icon: <PaperClipOutlined />,
      label: '퀵클립',
      onTitleClick: () => handleMenuClick('/quickclip'),
      children: quickclipCategories.map(category => ({
        key: `quickclip-${category}`,
        label: category,
        onClick: () => handleMenuClick(`/quickclip/${encodeURIComponent(category)}`),
      })),
    },
    {
      key: 'tangle',
      icon: <BlockOutlined  />,
      label: '탱글',
      onClick: () => handleMenuClick('/tangle'),
    },
    {
      key: 'archive',
      icon: <FolderOutlined />,
      label: '아카이브',
      onTitleClick: () => handleMenuClick('/archive'),
      children: archiveCategories.map(category => ({
        key: `archive-${category}`,
        label: category,
        onClick: () => handleMenuClick(`/archive/${encodeURIComponent(category)}`),
      })),
    },
  ];

  // Bottom menu items - only trash remains
  const bottomMenuItems = [
    {
      key: 'trash',
      icon: <DeleteOutlined />,
      label: '휴지통',
      onClick: () => handleMenuClick('/trash'),
    }
  ];

  const UploadButton = ({ collapsed }: { collapsed: boolean }) => {
    if (collapsed) {
      return (
        <Menu
          mode="inline"
          style={{ borderRight: 'none' }}
          items={[
            {
              key: 'upload',
              icon: <UploadOutlined />,
              label: '업로드',
              onClick: openModal,
            }
          ]}
        />
      );
    }

    return (
      <Button
        type="text"
        icon={<UploadOutlined />}
        onClick={openModal}
        style={{
          border: 'none',
          background: 'transparent',
          color: '#1890ff',
        }}
      />
    );
  };

  // Handle open keys on hover
  const handleOpenKeys = (key: string) => {
    if (collapsed) {
      setOpenKeys([key]);
    }
  };

  const handleCloseKeys = () => {
    if (collapsed) {
      setOpenKeys([]);
    }
  };

  return (
    <div style={customStyle}>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={toggleCollapse}
        trigger={null}
        collapsedWidth={80}
        breakpoint="lg"
        style={{
          height: '100%',
          position: 'fixed',
          left: 0,
          top: 0,
          bottom: 0,
          background: '#f5f5f5',
          borderRight: '1px solid #ddd',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {/* Logo Area */}
        <div
          className="logo"
          style={{
            height: '64px',
            display: 'flex',
            justifyContent: collapsed ? 'center' : 'space-between',
            alignItems: 'center',
            padding: collapsed ? '0' : '0 16px',
            background: '#fff',
            borderBottom: '1px solid #ddd',
          }}
        >
          <Link to="/">
            <img
              src="/icon-48 (2).png"
              alt="Ceep"
              style={{
                width: '32px',
                height: 'auto',
                transition: 'all 0.3s',
              }}
            />
          </Link>
          {!collapsed && <UploadButton collapsed={collapsed} />}
        </div>

        {/* Menu Container */}
        <div style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          height: 'calc(100% - 64px)',
          overflow: 'hidden',
        }}>
          {collapsed && <UploadButton collapsed={collapsed} />}
          
          {/* Main Menu */}
          <div style={{ flex: 1, overflowY: 'auto' }}>
            <Menu
              mode="inline"
              inlineCollapsed={collapsed}
              selectedKeys={[selectedKey]}
              openKeys={openKeys}
              onOpenChange={(keys) => setOpenKeys(keys)}
              items={mainMenuItems.map(item => ({
                ...item,
                onMouseEnter: () => handleOpenKeys(item.key),
                onMouseLeave: handleCloseKeys
              }))}
              style={{
                borderRight: 'none',
                height: '100%',
              }}
            />
          </div>

          {/* Bottom Fixed Menu */}
          <div style={{ 
            borderTop: '1px solid #ddd',
            backgroundColor: '#f5f5f5',
          }}>
            <Menu
              mode="inline"
              inlineCollapsed={collapsed}
              selectedKeys={[selectedKey]}
              onClick={({ key }) => setSelectedKey(key)}
              items={bottomMenuItems}
              style={{
                borderRight: 'none',
              }}
            />

            {/* Sidebar Toggle Button */}
            <div
              style={{
                padding: '16px',
                textAlign: 'center',
                cursor: 'pointer',
                transition: 'all 0.3s',
                borderTop: '1px solid #ddd',
              }}
              onClick={toggleCollapse}
            >
              {collapsed ? (
                <MenuUnfoldOutlined style={{ fontSize: '16px', color: '#595959' }} />
              ) : (
                <MenuFoldOutlined style={{ fontSize: '16px', color: '#595959' }} />
              )}
            </div>
          </div>
        </div>
      </Sider>

      <UploadModal
        open={isModalVisible}
        onClose={closeModal}
        onUpload={onUpload || (() => {})}
      />
    </div>
  );
};

export default Sidebar;