import React from 'react';
import { Row, Col, Card, Skeleton } from 'antd';

interface CategoryCardSkeletonProps {
  count?: number;
}

const CategoryCardSkeleton: React.FC<CategoryCardSkeletonProps> = ({ count = 12 }) => {
  return (
    <Row gutter={[24, 24]}>
      {[...Array(count)].map((_, index) => (
        <Col key={index} xs={24} sm={12} md={8} lg={6}>
          <Card
            style={{ 
              margin: '8px', 
              height: 'auto',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Skeleton 
              active 
              title={{ width: '100%' }}
              paragraph={false}
            />
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default CategoryCardSkeleton;