import axiosInstance from '../utils/axiosInstance';
import axios, { AxiosError } from 'axios';
import { AutocompleteResponse, AutocompleteSuggestion, SearchResponse, ObjType, OrderType, BookmarkResponse, SearchCategoryParams, SearchCategoryResponse  } from './../types/RootObj';

// 검색 자동완성 API
export const fetchSearchAutocomplete = async (query: string): Promise<AutocompleteResponse> => {
    try {
        
        const response = await axiosInstance.get<AutocompleteSuggestion[]>('/api/v1/objs/search-autocomplete', {
            params: { query },
        });
        
        if (Array.isArray(response.data)) {
            return { suggestions: response.data };
        } else {
            return { suggestions: [] };
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const axiosError = error as AxiosError;
    
        } else {
            // console.error('Unexpected error:', error);
        }
        return { suggestions: [] };
    }
};

// 메인 검색 API
export const fetchSearchResults = async (
    query: string,
    objType: ObjType = 'all',
    filters: {
        title?: boolean;
        keywords?: boolean;
        body?: boolean;
        summary?: boolean;
        bookmark?: boolean;
        unread?: boolean;
        order?: OrderType;
        page?: number;
        size?: number;
    } = {}
): Promise<SearchResponse> => {
    try {
        const response = await axiosInstance.get<SearchResponse>('/api/v1/objs/search', {
            params: {
                obj_type: objType,
                query,
                ...filters,
            },
        });
            return response.data;
        } catch (error) {
            throw new Error('검색 API 요청 중 오류 발생');
        }
};

// 북마크 조회

export const fetchBookmarks = async (page: number, size: number, type: ObjType): Promise<BookmarkResponse> => {
    try {
        const response = await axiosInstance.get<BookmarkResponse>('/api/v1/objs/bookmark', {
        params: {
            obj_type: type,
            page,
            size,
        },
        });
        return response.data;
    } catch (error) {
        // console.error('북마크를 불러오는 중 오류가 발생했습니다:', error);
        throw error;
    }
};

// 카테고리 카운트
export async function searchCategory(params: SearchCategoryParams): Promise<SearchCategoryResponse[]> {
    try {
        const response = await axiosInstance.get<SearchCategoryResponse[]>('/api/v1/objs/search-category', {
            params: params  // obj_type이 없으면 이 파라미터가 전달되지 않음
        });
    
        return response.data;
    } catch (error) {
        console.error('API 요청 중 오류 발생:', error);
        throw error;
    }
}

// 도메인 추출
export const getDomainNames = async () => {
    try{
        const response = await axiosInstance.get<string[]>('/api/v1/objs/domain-names');
        return response.data;
    } catch (error) {
        throw error;
    }
}