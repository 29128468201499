import { Extension } from '@tiptap/core';
import { Plugin, PluginKey } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';
import { Editor } from '@tiptap/core';
import { Level } from '@tiptap/extension-heading';
import { Node as ProseMirrorNode } from 'prosemirror-model';
import { EditorState } from 'prosemirror-state';

interface CommandItem {
  title: string;
  command: string;
  level?: Level;
}

interface SlashCommandOptions {
  commands: CommandItem[];
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    slashCommand: {
      showSlashMenu: (view: EditorView) => ReturnType;
      hideSlashMenu: () => ReturnType;
    }
  }
}

interface SlashCommandStorage {
  menuVisible: boolean;
  selectedIndex: number;
  currentMenu: HTMLElement | null;
  currentKeydownHandler: ((e: KeyboardEvent) => void) | null;
}

function isEmptyTextBlock(node: ProseMirrorNode): boolean {
  return node.isTextblock && !node.textContent.trim();
}

function isSlashAtNodeStart(state: EditorState): boolean {
  const { selection } = state;
  const $from = selection.$from;
  const parent = $from.parent;
  const parentOffset = $from.parentOffset;
  
  return parentOffset === 1 && parent.textContent.startsWith('/');
}

function executeCommand(editor: Editor, commandName: string, level?: Level) {
  const chain = editor.chain().focus();
  
  switch (commandName) {
    case 'toggleHeading':
      return level !== undefined ? chain.toggleHeading({ level }).run() : false;
    case 'toggleBold':
      return chain.toggleBold().run();
    case 'toggleItalic':
      return chain.toggleItalic().run();
    case 'toggleBulletList':
      return chain.toggleBulletList().run();
    case 'toggleOrderedList':
      return chain.toggleOrderedList().run();
    case 'toggleTaskList':
      return chain
      .focus()
      .toggleTaskList()
      .run();
    case 'toggleBlockquote':
      return chain.toggleBlockquote().run();
    case 'toggleCodeBlock':
      return chain.toggleCodeBlock().run();
    case 'setHorizontalRule':
      // 현재 선택 위치 저장
      const { from } = editor.state.selection;
      // 단일 트랜잭션으로 처리
      return editor
        .chain()
        .command(({ tr }) => {
          // 슬래시 문자 삭제
          tr.delete(from - 1, from);
          return true;
        })
        .setHorizontalRule()
        .insertContent('<p></p>')
        .focus()
        .run();
    default:
      return false;
  }
}

export const SlashCommand = Extension.create<SlashCommandOptions, SlashCommandStorage>({
  name: 'slashCommand',

  addOptions(): SlashCommandOptions {
    return {
      commands: [
        { title: '제목 1', command: 'toggleHeading', level: 1 as Level },
        { title: '제목 2', command: 'toggleHeading', level: 2 as Level },
        { title: '굵게', command: 'toggleBold' },
        { title: '기울임', command: 'toggleItalic' },
        { title: '글머리 기호', command: 'toggleBulletList' },
        { title: '번호 매기기', command: 'toggleOrderedList' },
        { title: '할 일 록록', command: 'toggleTaskList' },
        { title: '인용구', command: 'toggleBlockquote' },
        { title: '코드 블록', command: 'toggleCodeBlock' },
        { title: '구분선', command: 'setHorizontalRule' },
      ],
    };
  },

  addStorage(): SlashCommandStorage {
    return {
      menuVisible: false,
      selectedIndex: 0,
      currentMenu: null,
      currentKeydownHandler: null,
    };
  },

  addCommands() {
    return {
      hideSlashMenu: () => ({ commands }) => {
        if (this.storage.currentMenu) {
          this.storage.currentMenu.remove();
          this.storage.currentMenu = null;
        }
        if (this.storage.currentKeydownHandler) {
          document.removeEventListener('keydown', this.storage.currentKeydownHandler);
          this.storage.currentKeydownHandler = null;
        }
        this.storage.menuVisible = false;
        return true;
      },
      showSlashMenu:
        (view: EditorView) =>
        ({ editor }) => {
          const { commands } = this.options;
          
          this.editor.commands.hideSlashMenu();
          this.storage.selectedIndex = 0;

          const menu = document.createElement('div');
          menu.classList.add('slash-menu');
          this.storage.currentMenu = menu;

          const { from } = view.state.selection;
          const coords = view.coordsAtPos(from);
          
          const absoluteTop = coords.top + window.pageYOffset;
          const absoluteLeft = coords.left + window.pageXOffset;

          Object.assign(menu.style, {
            position: 'absolute',
            zIndex: '50',
            left: `${absoluteLeft}px`,
            top: `${absoluteTop + 20}px`,
          });

          commands.forEach((commandItem: CommandItem, index: number) => {
            const item = document.createElement('div');
            item.classList.add('slash-menu-item');
            if (index === 0) item.classList.add('selected');
            item.textContent = commandItem.title;
            
            item.addEventListener('click', () => {
              const { from } = view.state.selection;
              view.dispatch(view.state.tr.delete(from - 1, from));
              executeCommand(editor, commandItem.command, commandItem.level);
              this.editor.commands.hideSlashMenu();
            });

            item.addEventListener('mouseenter', () => {
              this.storage.selectedIndex = index;
              const items = menu.querySelectorAll('.slash-menu-item');
              items.forEach((item, i) => {
                if (i === index) {
                  item.classList.add('selected');
                } else {
                  item.classList.remove('selected');
                }
              });
            });
            
            menu.appendChild(item);
          });

          document.body.appendChild(menu);
          this.storage.menuVisible = true;

          const closeMenu = (e: MouseEvent) => {
            if (!menu.contains(e.target as Node)) {
              this.editor.commands.hideSlashMenu();
              document.removeEventListener('click', closeMenu);
            }
          };

          setTimeout(() => {
            document.addEventListener('click', closeMenu);
          }, 0);

          return false;
        },
    };
  },

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey('slashCommand'),
        props: {
          handleKeyDown: (view: EditorView, event: KeyboardEvent) => {
            // 메뉴가 열려있을 때 Enter 키 처리
            if (this.storage.menuVisible && event.key === 'Enter') {
              event.preventDefault();
              const items = this.storage.currentMenu?.querySelectorAll('.slash-menu-item');
              if (items && items[this.storage.selectedIndex]) {
                const commandItem = this.options.commands[this.storage.selectedIndex];
                const { from } = view.state.selection;
                view.dispatch(view.state.tr.delete(from - 1, from));
                executeCommand(this.editor, commandItem.command, commandItem.level);
                this.editor.commands.hideSlashMenu();
              }
              return true;
            }

            // 메뉴가 열려있을 때 방향키 처리
            if (this.storage.menuVisible && (event.key === 'ArrowUp' || event.key === 'ArrowDown')) {
              event.preventDefault();
              const items = this.storage.currentMenu?.querySelectorAll('.slash-menu-item');
              if (items) {
                if (event.key === 'ArrowUp') {
                  this.storage.selectedIndex = (this.storage.selectedIndex - 1 + items.length) % items.length;
                } else {
                  this.storage.selectedIndex = (this.storage.selectedIndex + 1) % items.length;
                }
                items.forEach((item, index) => {
                  if (index === this.storage.selectedIndex) {
                    item.classList.add('selected');
                  } else {
                    item.classList.remove('selected');
                  }
                });
              }
              return true;
            }

            // 슬래시 키 처리
            if (event.key === '/') {
              const state = view.state;
              const { selection } = state;
              const $from = selection.$from;
              
              const isAtStart = $from.parentOffset === 0;
              const parentNode = $from.parent;
              
              if (isAtStart || isEmptyTextBlock(parentNode)) {
                setTimeout(() => {
                  if (isSlashAtNodeStart(view.state)) {
                    this.editor.commands.showSlashMenu(view);
                  }
                }, 50);
              }
              return false;
            }

            // Escape 키 처리
            if (this.storage.menuVisible && event.key === 'Escape') {
              event.preventDefault();
              this.editor.commands.hideSlashMenu();
              return true;
            }

            return false;
          },
        },
        appendTransaction: (transactions, oldState, newState) => {
          const hasContentChanges = transactions.some(tr => tr.docChanged);
          
          if (hasContentChanges && this.storage.menuVisible) {
            const { selection } = newState;
            const { from } = selection;
            
            if (from > 0) {
              const prevChar = newState.doc.textBetween(from - 1, from);
              const parentOffset = selection.$from.parentOffset;
              
              if (prevChar !== '/' || parentOffset > 1) {
                setTimeout(() => {
                  this.editor.commands.hideSlashMenu();
                }, 0);
              }
            } else {
              setTimeout(() => {
                this.editor.commands.hideSlashMenu();
              }, 0);
            }
          }
          return null;
        }
      }),
    ];
  },
});