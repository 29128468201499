import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface PreferencesState {
    sortOrder: 'newest' | 'oldest' | 'viewCount';
}

const initialState: PreferencesState = {
    sortOrder: 'newest',
};

const preferencesSlice = createSlice({
    name: 'preferences',
    initialState,
    reducers: {
        setSortOrder: (state, action: PayloadAction<PreferencesState['sortOrder']>) => {
        state.sortOrder = action.payload;
        },
    },
});

export const { setSortOrder } = preferencesSlice.actions;
export default preferencesSlice.reducer;