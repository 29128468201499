import React, { useState, useEffect, CSSProperties } from 'react';
import { Layout, Button } from 'antd';
import { UpOutlined } from '@ant-design/icons';
import Sidebar from './Sidebar';
import Header from './Header';
import DailyUploadTimeline from './DailyUploadTimeline';

const { Content } = Layout;

interface AppLayoutProps {
  children: React.ReactNode;
  onUpload?: (newUpload: any) => void;
}

const AppLayout: React.FC<AppLayoutProps> = ({ children, onUpload }) => {
  const [collapsed, setCollapsed] = useState(() => {
    const savedState = sessionStorage.getItem('_sC');
    return savedState ? JSON.parse(savedState) : false;
  });
  const [showScrollButton, setShowScrollButton] = useState(false);

  useEffect(() => {
    sessionStorage.setItem('_sC', JSON.stringify(collapsed));
  }, [collapsed]);

  useEffect(() => {
    const handleScroll = () => {
      setShowScrollButton(window.scrollY > 300);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleCollapse = () => {
    setCollapsed((prevState: boolean) => !prevState);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  // 사이드바 너비 설정
  const sidebarWidth = collapsed ? 80 : 200;

  const layoutStyles: CSSProperties = {
    minHeight: '100vh',
    display: 'flex',
  };

  const mainLayoutStyles: CSSProperties = {
    marginLeft: sidebarWidth,
    transition: 'all 0.2s',
    width: `calc(100% - ${sidebarWidth}px)`,
    display: 'flex',
    flexDirection: 'column',
  };

  const contentStyles: CSSProperties = {
    flex: 1,
    padding: '16px',
    background: '#fff',
    position: 'relative',
    minHeight: 0,
    overflowY: 'auto',
  };

  const scrollButtonStyles: CSSProperties = {
    position: 'fixed',
    right: '24px',
    bottom: '80px',
    width: '40px',
    height: '40px',
    zIndex: 1000,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: 0.8,
    transition: 'opacity 0.3s',
  };

  return (
    <Layout style={layoutStyles}>
      <Sidebar
        collapsed={collapsed}
        toggleCollapse={toggleCollapse}
        onUpload={onUpload}
        customStyle={{ position: 'fixed', left: 0, top: 0, bottom: 0, zIndex: 1000 }}
      />
      <Layout style={mainLayoutStyles}>
        <Header customStyle={{ position: 'sticky', top: 0, zIndex: 1, width: '100%' }} />
        <Content style={contentStyles}>
          {children}
        </Content>
        {showScrollButton && (
          <Button
            type="primary"
            shape="circle"
            icon={<UpOutlined />}
            onClick={scrollToTop}
            style={scrollButtonStyles}
            onMouseEnter={(e) => {
              e.currentTarget.style.opacity = '1';
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.opacity = '0.8';
            }}
          />
        )}
        <DailyUploadTimeline />
      </Layout>
    </Layout>
  );
};

export default AppLayout;