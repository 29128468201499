// import React, { useState } from 'react';
// import { Layout, Menu, Form, Input, Switch, Button, Divider, Modal } from 'antd';
// import { useNavigate } from 'react-router-dom';
// import { useAuth } from '../contexts'; 
// import styles from '../styles/Settings.module.css';

// const { Content, Sider } = Layout;

// const Settings: React.FC = () => {
//   const navigate = useNavigate();
//   const { logout } = useAuth();
//   const [selectedMenu, setSelectedMenu] = useState('profile');
//   const [deleteAccountModal, setDeleteAccountModal] = useState(false);

//   // 회원 탈퇴 확인
//   const showDeleteConfirm = () => {
//     setDeleteAccountModal(true);
//   };

//   const handleDeleteAccount = async () => {
//     // 회원 탈퇴 로직 구현
//     await logout();
//     navigate('/signin');
//   };

//   const menuItems = [
//     {
//       key: 'profile',
//       label: '프로필',
//     },
//     {
//       key: 'consent',
//       label: '동의 설정',
//     },
//     {
//       key: 'delete',
//       label: '회원 탈퇴',
//     },
//   ];

//   const renderContent = () => {
//     switch (selectedMenu) {
//       case 'profile':
//         return (
//           <div className={styles.section}>
//             <h2>프로필 설정</h2>
//             <Form layout="vertical">
//               <Form.Item label="이름" name="fullName">
//                 <Input placeholder="이름을 입력하세요" />
//               </Form.Item>
//               <Form.Item label="닉네임" name="nickname">
//                 <Input placeholder="닉네임을 입력하세요" />
//               </Form.Item>
//               <Form.Item label="이메일" name="email">
//                 <Input disabled placeholder="example@email.com" />
//               </Form.Item>
//               <Button type="primary">저장</Button>
//             </Form>
//           </div>
//         );

//       case 'consent':
//         return (
//           <div className={styles.section}>
//             <h2>동의 설정</h2>
//             <div className={styles.consentItem}>
//               <div>
//                 <h3>이메일 수신 동의</h3>
//                 <p>새로운 기능과 업데이트 소식을 이메일로 받아보세요.</p>
//               </div>
//               <Switch defaultChecked />
//             </div>
//             <Divider />
//             <div className={styles.consentItem}>
//               <div>
//                 <h3>데이터 수집 동의</h3>
//                 <p>서비스 개선을 위한 사용자 데이터 수집에 동의합니다.</p>
//               </div>
//               <Switch defaultChecked />
//             </div>
//           </div>
//         );

//       case 'delete':
//         return (
//           <div className={styles.section}>
//             <h2>회원 탈퇴</h2>
//             <div className={styles.deleteAccount}>
//               <h3>회원 탈퇴 시 주의사항</h3>
//               <ul>
//                 <li>모든 데이터가 영구적으로 삭제됩니다.</li>
//                 <li>삭제된 데이터는 복구할 수 없습니다.</li>
//                 <li>진행 중인 구독이 있다면 즉시 취소됩니다.</li>
//               </ul>
//               <Button danger onClick={showDeleteConfirm}>
//                 회원 탈퇴
//               </Button>
//             </div>
//           </div>
//         );

//       default:
//         return null;
//     }
//   };

//   return (
//     <Layout className={styles.settingsLayout}>
//       <Sider width={200} theme="light" className={styles.sider}>
//         <Menu
//           mode="vertical"
//           selectedKeys={[selectedMenu]}
//           onClick={({ key }) => setSelectedMenu(key)}
//           items={menuItems}
//         />
//       </Sider>
//       <Content className={styles.content}>
//         {renderContent()}
//       </Content>

//       <Modal
//         title="회원 탈퇴 확인"
//         open={deleteAccountModal}
//         onOk={handleDeleteAccount}
//         onCancel={() => setDeleteAccountModal(false)}
//         okText="탈퇴하기"
//         cancelText="취소"
//         okButtonProps={{ danger: true }}
//       >
//         <p>정말로 탈퇴하시겠습니까? 이 작업은 취소할 수 없습니다.</p>
//       </Modal>
//     </Layout>
//   );
// };

// export default Settings;

import React, { useState } from 'react';
import { Layout, Menu, Form, Switch, Button, Modal, message, Input, Typography } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts';
import { useUserInfo, useUpdateUserDetail, useUnsubscribe } from '../hooks/useUserInfo';
import styles from '../styles/Settings.module.css';

const { Content, Sider } = Layout;
const { Text, Paragraph } = Typography;

const Settings: React.FC = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { logout } = useAuth();
  const [selectedMenu, setSelectedMenu] = useState('consent');
  const [deleteAccountModal, setDeleteAccountModal] = useState(false);
  const [emailError, setEmailError] = useState<string | null>(null);
  
  const { data: userInfo, isLoading } = useUserInfo();
  const { mutate: updateUserDetail } = useUpdateUserDetail();
  const { mutate: unsubscribe, isPending: isUnsubscribing } = useUnsubscribe();

  const menuItems = [
    {
      key: 'consent',
      label: '동의 설정',
    },
    {
      key: 'delete',
      label: '회원 탈퇴',
    },
  ];

  const handleConsentChange = (type: 'emailAdvertising' | 'dataSharing') => (checked: boolean) => {
    if (userInfo) {
      updateUserDetail({
        emailAdvertising: type === 'emailAdvertising' ? checked : userInfo.emailAdvertising,
        dataSharing: type === 'dataSharing' ? checked : userInfo.dataSharing
      });
    }
  };

  const handleDeleteAccount = async (values: { email: string }) => {
    if (values.email !== userInfo?.email) {
      setEmailError('이메일이 일치하지 않습니다.');
      return;
    }

    try {
      unsubscribe(undefined, {
        onSuccess: () => {
          message.success('회원 탈퇴가 완료되었습니다.');
          form.resetFields();
          setEmailError(null);
        },
        onError: () => {
          message.error('회원 탈퇴 처리 중 오류가 발생했습니다.');
          setDeleteAccountModal(false);
          setEmailError(null);
        }
      });
    } catch (error) {
      message.error('회원 탈퇴 처리 중 오류가 발생했습니다.');
      setDeleteAccountModal(false);
      setEmailError(null);
    }
  };

  const closeModal = () => {
    form.resetFields();
    setDeleteAccountModal(false);
  };

  const renderContent = () => {
    switch (selectedMenu) {
      case 'consent':
        return (
          <div className={styles.section}>
            <h2>동의 설정</h2>
            <div className={styles.consentItem}>
              <div>
                <h3>이메일 수신 동의</h3>
                <p>새로운 기능과 업데이트 소식을 이메일로 받아보세요.</p>
              </div>
              <Switch 
                checked={userInfo?.emailAdvertising}
                onChange={handleConsentChange('emailAdvertising')}
              />
            </div>

            <div className={styles.consentItem}>
              <div>
                <h3>데이터 수집 동의</h3>
                <p>서비스 개선을 위한 사용자 데이터 수집에 동의합니다.</p>
              </div>
              <Switch 
                checked={userInfo?.dataSharing}
                onChange={handleConsentChange('dataSharing')}
              />
            </div>
          </div>
        );

      case 'delete':
        return (
          <div className={styles.section}>
            <h2>회원 탈퇴</h2>
            <div className={styles.deleteAccount}>
              <h3>회원 탈퇴 시 주의사항</h3>
              <ul>
                <li>모든 데이터는 영구적으로 삭제됩니다.</li>
                <li>삭제된 데이터는 복구할 수 없습니다.</li>
              </ul>
              <Button danger onClick={() => setDeleteAccountModal(true)}>
                회원 탈퇴
              </Button>
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div style={{ height: 'calc(100vh - 64px)' }}>
      <Layout className={styles.settingsLayout}>
        <Sider 
          width={200} 
          theme="light" 
          className={styles.sider}
          style={{ height: '100%' }}
        >
          <Menu
            mode="vertical"
            selectedKeys={[selectedMenu]}
            onClick={({ key }) => setSelectedMenu(key)}
            items={menuItems}
            style={{ borderRight: 'none' }}
          />
        </Sider>
        <Content className={styles.content}>
          {renderContent()}
        </Content>

        <Modal
          title="계정 삭제"
          open={deleteAccountModal}
          onCancel={closeModal}
          footer={null}
          width={520}
          className={styles.deleteModal}
        >
          <div style={{ padding: '8px 0 24px' }}>
            <div style={{ marginBottom: '24px' }}>
              <Paragraph style={{ marginTop: '8px', color: '#666' }}>
                삭제된 계정 복구할 수 없습니다.
              </Paragraph>
            </div>

            <Form
              form={form}
              onFinish={handleDeleteAccount}
              layout="vertical"
            >
              <div style={{
                background: '#fff2f0',
                border: '1px solid #ffccc7',
                borderRadius: '6px',
                padding: '16px',
                marginBottom: '24px'
              }}>
                <div style={{ marginBottom: '16px' }}>
                  <Text strong>확인을 위해 이메일 주소를 입력하세요:</Text>
                  <br />
                  <Text code style={{ fontSize: '14px' }}>{userInfo?.email}</Text>
                </div>

                <Form.Item
                    name="email"
                    style={{ marginBottom: emailError ? '24px' : 0 }}
                    validateStatus={emailError ? 'error' : ''}
                    help={emailError}
                    rules={[
                    { required: true, message: '이메일을 입력해주세요.' }
                    ]}
                >
                    <Input 
                    placeholder="이메일 입력"
                    style={{ backgroundColor: 'white' }}
                    onChange={() => setEmailError(null)}
                    onKeyDown={() => setEmailError(null)}
                    />
                </Form.Item>
              </div>

              <div style={{ display: 'flex', gap: '8px', justifyContent: 'flex-end' }}>
                <Button 
                  onClick={closeModal}
                  disabled={isUnsubscribing}
                >
                  취소
                </Button>
                <Button
                  danger
                  type="primary"
                  htmlType="submit"
                  loading={isUnsubscribing}
                >
                  {isUnsubscribing ? '삭제 중...' : '계정 삭제 확인'}
                </Button>
              </div>
            </Form>
          </div>
        </Modal>
      </Layout>
    </div>
  );
};

export default Settings;