import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, message, Pagination, Spin, Typography, Select, Empty, Checkbox, Button, Space, notification } from 'antd';
import QuickClipCard from '../components/ui/card/QuickClipCard';
import FileCard from '../components/ui/card/FileCard';
import { ObjDoc, File, Article, Piece, isFile, isArticle, isPiece } from '../types/ChildObj';
import { ObjSearchType } from '../types/RootObj';
import { setDeletedAt, toggleBookmark } from '../api/ChildObj';
import { fetchBookmarks } from '../api/RootObj';
import { updateFile } from '../api/File';
import { UpdatePiece } from '../api/piece';
import { updateArticleData } from '../api/Article';
import { downloadFile } from '../utils/downloadFile';

const { Title } = Typography;
const { Option } = Select;

type BookmarkItem = File | Article | Piece;

const BookmarkPage: React.FC = () => {
    const [bookmarks, setBookmarks] = useState<BookmarkItem[]>([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(12);
    const [total, setTotal] = useState(0);
    const [objSearchType, setObjSearchType] = useState<ObjSearchType>('all');
    const [selectedItems, setSelectedItems] = useState<string[]>([]);
    const [selectAll, setSelectAll] = useState(false);

    const navigate = useNavigate();

    const loadBookmarks = async (page: number, size: number, type: ObjSearchType) => {
        setLoading(true);
        try {
            const response = await fetchBookmarks(page, size, type);
            // 응답을 BookmarkItem 타입으로 캐스팅
            setBookmarks(response.items as BookmarkItem[]);
            setTotal(response.total);
        } catch (error) {
            message.error('북마크를 불러오는 중 오류가 발생했습니다.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        loadBookmarks(currentPage, pageSize, objSearchType);
    }, [currentPage, pageSize, objSearchType]);

    // 스크롤 관련 함수
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const handlePageChange = (page: number, pageSize?: number) => {
        setCurrentPage(page);
        if (pageSize) setPageSize(pageSize);
        scrollToTop();
    };

    useEffect(() => {
        scrollToTop();
    }, [currentPage]);

    const handleTypeChange = (value: ObjSearchType) => {
        setObjSearchType(value);
        setCurrentPage(1);
        // 타입 변경 시 선택 초기화
        setSelectedItems([]);
        setSelectAll(false);
    };

    const handleViewCountChange = useCallback((id: string, newCount: number) => {
        setBookmarks(prevBookmarks => 
            prevBookmarks.map(item => 
                item._id === id ? { ...item, views: newCount } : item
            )
        );
    }, []);

    const handleBookmarkToggle = (id: string) => {
        setBookmarks(prevBookmarks => prevBookmarks.filter(item => item._id !== id));
        message.success('북마크에서 제거되었습니다.');
    };

    const handleDelete = async (id: string) => {
        try {
            const itemToDelete = bookmarks.find(item => item._id === id);
            if (itemToDelete) {
                await setDeletedAt(itemToDelete);
                setBookmarks(prevBookmarks => prevBookmarks.filter(item => item._id !== id));
                message.success('아이템이 삭제되었습니다.');
            }
        } catch (error) {
            message.error('아이템 삭제에 실패했습니다.');
        }
    };

    const handleEdit = async (id: string, newTitle: string, newKeywords: string[] = []) => {
        try {
          const itemToEdit = bookmarks.find(item => item._id === id);
          if (itemToEdit && itemToEdit._id) {
            let success = false;
      
            if (isFile(itemToEdit)) {
              success = await updateFile(itemToEdit._id, {
                title: newTitle,
                keywords: newKeywords
              });
            } else if (isPiece(itemToEdit)) {
              const updateData = {
                title: newTitle,
                keywords: newKeywords
              };
              await UpdatePiece.updatePieceContent(itemToEdit._id, updateData);
              success = true;
            } else if (isArticle(itemToEdit)) {
              await updateArticleData(itemToEdit._id, {
                title: newTitle,
                keywords: newKeywords
              });
              success = true;
            }
      
            if (success) {
              setBookmarks(prevBookmarks => 
                prevBookmarks.map(item => 
                  item._id === id 
                    ? { ...item, title: newTitle, keywords: newKeywords }
                    : item
                )
              );
              
            } else {
              message.error('수정에 실패했습니다.');
            }
          }
        } catch (error) {
          message.error('수정 중 오류가 발생했습니다.');
        }
      };

    // 전체 선택 관련 핸들러 추가
    const handleSelectAll = (checked: boolean) => {
        setSelectAll(checked);
        if (checked) {
            setSelectedItems(bookmarks.map(item => item._id || '').filter(id => id !== ''));
        } else {
            setSelectedItems([]);
        }
    };

    const handleSelectItem = (id: string, checked: boolean) => {
        setSelectedItems(prev => {
            const newSelected = checked 
                ? [...prev, id]
                : prev.filter(item => item !== id);
            
            setSelectAll(newSelected.length === bookmarks.length);
            return newSelected;
        });
    };

    // 선택된 항목 일괄 삭제
    const handleBulkDelete = async () => {
        try {
            await Promise.all(
                selectedItems.map(id => {
                    const itemToDelete = bookmarks.find(item => item._id === id);
                    return itemToDelete ? setDeletedAt(itemToDelete) : Promise.resolve();
                })
            );
    
            setBookmarks(prev => prev.filter(item => !selectedItems.includes(item._id || '')));
            setSelectedItems([]);
            setSelectAll(false);
    
            notification.open({
                message: '항목들을 휴지통으로 이동했습니다.',
                description: '30일 후에 영구삭제 됩니다.',
                placement: 'topRight',
                btn: (
                    <Button 
                        type="primary" 
                        size="small" 
                        onClick={() => navigate('/trash')}
                        style={{ 
                            borderRadius: '20px',
                            marginTop: '8px'
                        }}
                    >
                        휴지통 바로가기
                    </Button>
                ),
                style: {
                    borderRadius: '8px'
                },
                duration: 4.5
            });
        } catch (error) {
            message.error('일괄 삭제 중 오류가 발생했습니다.');
        }
    };

    // 선택된 항목 북마크 일괄 해제
    const handleBulkUnbookmark = async () => {
        try {
            await Promise.all(
                selectedItems.map(id => {
                    const itemToUnbookmark = bookmarks.find(item => item._id === id);
                    return itemToUnbookmark ? toggleBookmark(itemToUnbookmark) : Promise.resolve();
                })
            );
    
            setBookmarks(prev => prev.filter(item => !selectedItems.includes(item._id || '')));
            setSelectedItems([]);
            setSelectAll(false);
    
            notification.open({
                message: '항목들의 북마크를 해제했습니다.',
                placement: 'topRight',
                style: {
                    borderRadius: '8px'
                },
                duration: 3
            });
        } catch (error) {
            message.error('북마크 해제 중 오류가 발생했습니다.');
        }
    };

    const handleDownload = async (id: string) => {
        try {
            await downloadFile(id);
            message.success('파일 다운로드가 시작됩니다.');
        } catch (error) {
            message.error('파일 다운로드에 실패했습니다.');
        }
    };

    const getTitle = () => {
        switch (objSearchType) {
            case 'article':
                return '아티클 북마크';
            case 'piece':
                return '피스 북마크';
            case 'file':
                return '파일 북마크';
            case 'quickclip':
                return '퀵클립 북마크';
            default:
                return '북마크';
        }
    };

    return (
        <div style={{ padding: '24px', minHeight: '100vh' }}>
            <Title level={2} style={{ marginBottom: '24px' }}>{getTitle()}</Title>
            
            <Row gutter={[16, 16]} style={{ marginBottom: '24px' }} align="middle" justify="space-between">
                <Col xs={24} sm={8}>
                    <Select
                        style={{ width: '100%' }}
                        placeholder="타입 선택"
                        onChange={handleTypeChange}
                        value={objSearchType}
                    >
                        <Option value="all">전체</Option>
                        <Option value="article">아티클</Option>
                        <Option value="piece">피스</Option>
                        <Option value="file">파일</Option>
                        <Option value="quickclip">퀵클립</Option>
                    </Select>
                </Col>
    
                {bookmarks.length > 0 && (
                    <Col xs={24} sm={16} style={{ 
                        display: 'flex', 
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        gap: '12px'
                    }}>
                        <Checkbox
                            checked={selectAll}
                            onChange={e => handleSelectAll(e.target.checked)}
                        >
                            전체 선택
                        </Checkbox>
                        {selectedItems.length > 0 && (
                            <Space split={<span style={{ color: '#e8e8e8' }}>|</span>}>
                                <Button 
                                    type="text"
                                    danger 
                                    onClick={handleBulkDelete}
                                >
                                    선택 항목 삭제 ({selectedItems.length})
                                </Button>
                                <Button 
                                    type="text"
                                    onClick={handleBulkUnbookmark}
                                >
                                    선택 항목 북마크 해제 ({selectedItems.length})
                                </Button>
                            </Space>
                        )}
                    </Col>
                )}
            </Row>
            <Spin spinning={loading} tip="북마크 로딩 중...">
                {bookmarks.length > 0 ? (
                    <Row gutter={[16, 16]}>
                    {bookmarks.map((item) => (
                        <Col xs={24} sm={12} md={8} lg={6} key={item._id}>
                            <div style={{ 
                                position: 'relative',
                                cursor: 'pointer'
                            }}>
                                <div style={{
                                    position: 'absolute',
                                    top: '10px',
                                    left: '10px',
                                    zIndex: 10
                                }}>
                                    <Checkbox
                                        checked={selectedItems.includes(item._id || '')}
                                        onChange={e => handleSelectItem(item._id || '', e.target.checked)}
                                        onClick={e => e.stopPropagation()}
                                        style={{
                                            transform: 'scale(1.3)' // 체크박스 크기 키우기
                                        }}
                                    />
                                </div>
                                {isFile(item) ? (
                                    <FileCard
                                        file={item}
                                        onViewCountChange={handleViewCountChange}
                                        onBookmarkToggle={() => {
                                            if (item._id) handleBookmarkToggle(item._id);
                                        }}
                                        onDelete={handleDelete}
                                        onEdit={handleEdit}
                                    />
                                ) : (isArticle(item) || isPiece(item)) && (
                                    <QuickClipCard
                                        obj={item}
                                        onViewCountChange={handleViewCountChange}
                                        onBookmarkToggle={() => {
                                            if (item._id) handleBookmarkToggle(item._id);
                                        }}
                                        onDelete={handleDelete}
                                        onEdit={handleEdit}
                                    />
                                )}
                            </div>
                        </Col>
                    ))}
                </Row>
                ) : (
                    <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description="북마크가 없습니다. 새로운 콘텐츠를 북마크해보세요!"
                    />
                )}
            </Spin>
            
            {(total > 0 && bookmarks.length > 0) && (
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '24px' }}>
                    <Pagination
                        current={currentPage}
                        pageSize={pageSize}
                        total={total}
                        onChange={handlePageChange}
                        showSizeChanger
                        showQuickJumper
                        showTotal={(total) => `총 ${total}개의 ${getTitle()}`}
                    />
                </div>
            )}
        </div>
    );
};

export default BookmarkPage;
