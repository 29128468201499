import axiosInstance from "../utils/axiosInstance";
import { Article } from "../types/Article";
import { ObjDoc } from '../types/ChildObj';
import axios from 'axios';



// 링크 업로드 함수
export const uploadArticle = async (linkUrl: string): Promise<Article> => {
    try {
        const response = await axiosInstance.post<Article>('/api/v1/articles', {
            linkUrl,
        }, {
            headers: {
                'Content-Type': 'application/json',
            }
        });

        return response.data;  
    } catch (error) {
        // console.error('링크 업로드 실패:', error);
        throw error;
    }
}

// 영구 삭제
export const articleDelete = async (objDoc: ObjDoc): Promise<boolean> => {
    try {
        if (!objDoc._id) {
            throw new Error('Invalid objDoc: missing _id');
        }

        const url = `/api/v1/articles/${objDoc._id}/`;

        await axiosInstance.delete(url);

        return true;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            // console.error('삭제 처리 중 오류 발생:', error);
        }
        return false;
    }
};

export const updateArticleData = async (
    articleId: string,
    data: {
        title?: string;
        keywords?: string[];
    }
): Promise<boolean> => {
    try {
        await axiosInstance.patch(`/api/v1/articles/${articleId}/`, data);
        return true;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw error.response?.data?.message || '서버 오류가 발생했습니다.';
        }
        return false;
    }
};


