import { generatePresignedUrl } from '../api/File';
import { message } from 'antd';

export const downloadFile = async (fileId: string) => {
  if (!fileId) {
    throw new Error('파일 ID가 필요합니다.');
  }

  try {
    const { presignedUrl, fileType, title } = await generatePresignedUrl(fileId);
    
    // iframe을 사용한 다운로드
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = presignedUrl;
    document.body.appendChild(iframe);
    
    message.success('파일 다운로드를 시작합니다.');
    
    // 잠시 후 iframe 제거
    setTimeout(() => {
      document.body.removeChild(iframe);
    }, 2000);

    return { success: true };
  } catch (error) {
    console.error('File download failed:', error);
    message.error('파일 다운로드에 실패했습니다. 오류: ' + (error as Error).message);
    throw error;
  }
};