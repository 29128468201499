import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { getUserInfo, updateUserDetail, unsubscribe } from '../api/user';
import { UserInfo } from '../types/user';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { message } from 'antd';
import axios from 'axios';

interface UpdateUserDetailRequest {
    emailAdvertising?: boolean;
    dataSharing?: boolean;
}

export const useUserInfo = () => {
    const navigate = useNavigate();
    const { logout } = useAuth();

    return useQuery<UserInfo>({
        queryKey: ['userInfo'],
        queryFn: async () => {
            try {
                const data = await getUserInfo();
                if (data._id) {
                    localStorage.setItem('_u', data._id);
                }
                return data;
            } catch (error) {
                if (axios.isAxiosError(error) && error.response?.status === 400) {
                    message.error('토큰이 만료되었습니다. 다시 로그인해주세요.');
                    localStorage.removeItem('_u');
                    await logout();
                    // navigate('/signin');
                    navigate('/cbetasi');
                }
                throw error;
            }
        },
        staleTime: Infinity
    });
};

export const useUpdateUserDetail = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (updateParams: UpdateUserDetailRequest) => updateUserDetail(updateParams),
        onSuccess: () => {
            // userInfo 쿼리를 무효화하여 새로운 데이터를 가져오게 함
            queryClient.invalidateQueries({ queryKey: ['userInfo'] });
        }
    });
};

export const useUnsubscribe = () => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const { logout } = useAuth();

    return useMutation({
        mutationFn: unsubscribe,
        onSuccess: async () => {
            // 탈퇴 성공 시 캐시 초기화
            queryClient.clear();
            // 로그아웃 처리
            await logout();
            // 로그인 페이지로 이동
            // navigate('/signin');
            navigate('/cbetasi');

        }
    });
};