import { configureStore } from '@reduxjs/toolkit';
import categoryReducer from './slices/categorySlice';
import preferencesReducer from './slices/preferencesSlice';

export const store = configureStore({
    reducer: {
        category: categoryReducer,
        preferences: preferencesReducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;