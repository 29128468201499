import axiosInstance from "../utils/axiosInstance";
import { Pin } from "../types/pin";


export const getPins = async (): Promise<Pin[]> => {
    try {
        const response = await axiosInstance.get<Pin[]>(`/api/v1/pins`);
        return response.data;
    } catch (error) {
        // console.error('Error fetching pins:', error);
        throw error;
    }
};


export const togglePin = async (objDocId: string): Promise<boolean> => {
    try {
        const response = await axiosInstance.post(`/api/v1/pins/objs/${objDocId}/`);
        return response.data;
    } catch (error) {
        // console.error('Error toggling pin:', error);
        throw error;
    }
};