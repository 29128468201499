import axiosInstance from '../utils/axiosInstance';
import { SearchParams, SearchResponse, AutocompleteItem, 
        CategorySearchParams, CategorySearchResponse, LinkUrlsResponse, LinkDetails, OrderType, ObjType } from '../types/search';

type AutocompleteResponse = AutocompleteItem[];


export const searchAPI = async (
    query: string,
    obj_type: ObjType = 'all',
    filters: {
        title?: boolean;
        keywords?: boolean;
        body?: boolean;
        summary?: boolean;
        bookmark?: boolean;
        unread?: boolean;
        order?: OrderType;
        page?: number;
        size?: number;
    } = {}
): Promise<SearchResponse> => {
    try {
        const response = await axiosInstance.get<SearchResponse>('/api/v1/objs/search', {
            params: {
                obj_type,
                query,
                ...filters,
            },
        });
            return response.data;
        } catch (error) {
            throw new Error('검색 API 요청 중 오류 발생');
        }
};

export const searchAutocompleteAPI = async (query: string, type: ObjType): Promise<AutocompleteResponse> => {
    try {
        const response = await axiosInstance.get<AutocompleteResponse>('/api/v1/objs/search-autocomplete', {
            params: { query, obj_type: type }
        });
        return response.data;
    } catch (error) {
        // console.error('Error in search autocomplete API:', error);
        throw error;
    }
};

export const searchCategoryAPI = async (params: CategorySearchParams): Promise<CategorySearchResponse> => {
    try {
        const response = await axiosInstance.get<CategorySearchResponse>('/api/v1/objs/search-category', { params });
        return response.data;
    } catch (error) {
        // console.error('Error in category search API:', error);
        throw error;
    }
};

export const getLinkUrlsAPI = async (): Promise<LinkUrlsResponse> => {
    try {
        const response = await axiosInstance.get<LinkUrlsResponse>('/api/v1/objs/link-urls');
        return response.data;
    } catch (error) {
        // console.error('Error in get link URLs API:', error);
        throw error;
    }
};

export const getLinkDetailsAPI = async (linkUrl: string): Promise<LinkDetails> => {
    try {
        const encodedUrl = encodeURIComponent(linkUrl);
        const response = await axiosInstance.get<LinkDetails>(`/api/v1/objs/link-urls/${encodedUrl}/`);
        return response.data;
    } catch (error) {
        // console.error('Error in get link details API:', error);
        throw error;
    }
};