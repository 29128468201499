import React, { useState } from 'react';
import { Card, Badge, Tooltip, Popover, message, Menu, Dropdown, notification, Button } from 'antd';
import { StarOutlined, StarFilled, EyeOutlined, MoreOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Tangle } from '../../../types/Tangle';
import { setDeletedAt, toggleBookmark, increaseViews } from '../../../api/ChildObj';
import { UpdateTangle } from '../../../api/Tangle'; 
import { useNavigate } from 'react-router-dom';
import { MenuInfo } from 'rc-menu/lib/interface';
import EditModal from '../../ui/modal/EditModal';

const { Meta } = Card;

interface TangleCardProps {
 tangle: Tangle;
 onView?: (tangle: Tangle) => void;
 onDelete?: (id: string) => void;
 onEdit: (id: string, newTitle: string) => void;
}

const TangleCard: React.FC<TangleCardProps> = ({ 
 tangle,
 onView,
 onDelete,
 onEdit
}) => {
 const navigate = useNavigate();
 const [isHovered, setIsHovered] = useState(false);
 const [views, setViews] = useState(tangle.views);
 const [isBookmarked, setIsBookmarked] = useState(tangle.bookmark);
 const [isEditModalVisible, setIsEditModalVisible] = useState(false);

 const styles = {
   card: {
     position: 'relative' as 'relative',
     overflow: 'hidden',
   },
   imageContainer: {
     position: 'relative' as 'relative',
     height: '190px',
   },
   overlay: {
     position: 'absolute' as const,
     top: 0,
     left: 0,
     width: '100%',
     height: '100%',
     backgroundColor: 'rgba(0, 0, 0, 0.85)',
     display: 'flex',
     alignItems: 'center',
     justifyContent: 'center',
     opacity: isHovered ? 1 : 0,
     transition: 'opacity 0.3s ease',
     zIndex: 1,
   },
   actions: {
     position: 'absolute' as const,
     top: '10px',
     right: '10px',
     zIndex: 3,
     display: 'flex',
     gap: '15px',
     transition: 'color 0.3s ease',
     color: isHovered ? 'white' : 'rgba(0, 0, 0, 0.65)',
   },
   icon: {
     fontSize: '20px',
     cursor: 'pointer',
   },
   iconContainer: {
     display: 'flex',
     justifyContent: 'flex-end',
     paddingTop: '5px',
     marginBottom: '10px',
     gap: '15px',
   },
 };

 const tangleToObjDoc = {
   ...tangle,
   summary: '',
   keywords: [],
   thumbnail: tangle.pieces[0]?.thumbnail || null,
   usage: {
     completion_tokens: 0,
     prompt_tokens: 0,
     total_tokens: 0,
     completion_tokens_details: null
   },
   type: 'tangle' as const
 };

 const handleView = async () => {
   if (tangle._id) {
     try {
       const newCount = await increaseViews(tangleToObjDoc);
       if (newCount !== null) {
         setViews(newCount);
       }
       if (onView) {
         onView(tangle);
       }
       navigate(`/tangle/${encodeURIComponent(tangle.title)}`, {
         state: { piece: tangle }
       });
     } catch (error) {
       message.error('조회수 업데이트에 실패했습니다.');
     }
   }
 };

 const handleBookmark = async (e: React.MouseEvent) => {
   e.stopPropagation();
   if (tangle._id) {
     try {
       const newBookmarkStatus = await toggleBookmark(tangleToObjDoc);
       setIsBookmarked(newBookmarkStatus);
     } catch (error) {
       message.error('북마크 상태 변경에 실패했습니다.');
     }
   }
 };

 const handleDelete = async () => {
   if (tangle._id) {
     try {
       const success = await setDeletedAt(tangleToObjDoc);
       if (success) {
         if (onDelete) {
           onDelete(tangle._id);
         }
         notification.open({
           message: '탱글이 삭제되었습니다',
           description: '휴지통에 30일 동안 보관되며, 그 후에 영구 삭제됩니다.',
           className: 'custom-notification',
           style: {
             borderRadius: 8,
           },
           duration: 4.5,
           btn: (
             <div className="custom-notification-content">
               <div className="custom-notification-button-container">
                 <Button 
                   type="primary" 
                   size="small" 
                   onClick={() => navigate('/trash')}
                   className="custom-notification-btn"
                 >
                   휴지통 바로가기
                 </Button>
               </div>
             </div>
           ),
         });
       } else {
         message.error('휴지통으로 이동 실패');
       }
     } catch (error) {
       message.error('삭제에 실패했습니다.');
     }
   }
 };

 const handleEditModalSave = async (newTitle: string) => {
  if (tangle._id) {
    try {
      const success = await UpdateTangle.updateTangleContent(tangle._id, { 
        title: newTitle 
      });
      if (success) {
        onEdit(tangle._id, newTitle);
      }
    } catch (error) {
      message.error('수정 중 오류가 발생했습니다.');
    }
  }
  setIsEditModalVisible(false);
};

 const menu = (
   <Menu
     onClick={(e: MenuInfo) => e.domEvent.stopPropagation()}
   >
     <Menu.Item
       key="edit"
       onClick={() => setIsEditModalVisible(true)}
       icon={<EditOutlined />}
     >
       수정
     </Menu.Item>
     <Menu.Item
       key="delete"
       onClick={handleDelete}
       icon={<DeleteOutlined />}
     >
       삭제
     </Menu.Item>
   </Menu>
 );

 const renderThumbnails = () => {
   const thumbnails = tangle.pieces.slice(0, 4);
   const count = thumbnails.length;

   if (count === 0) {
     return (
       <div style={{ 
         height: '100%', 
         display: 'flex', 
         alignItems: 'center', 
         justifyContent: 'center', 
         backgroundColor: '#f0f0f0' 
       }}>
         <span>No Images</span>
       </div>
     );
   }

   return (
     <>
       <div style={{ 
         display: 'flex', 
         flexWrap: 'wrap', 
         height: '100%', 
         gap: '2px' 
       }}>
         {thumbnails.map((piece, index) => (
           <img
             key={piece.pieceId}
             referrerPolicy="no-referrer"
             src={piece.thumbnail || "/error_image.png"}
             alt={`Thumbnail ${index + 1}`}
             style={{
               objectFit: 'cover',
               height: count === 1 ? '100%' : count === 2 ? '50%' : '50%',
               width: count === 1 ? '100%' : count === 2 ? '100%' : '50%',
             }}
             onError={(e) => {
               e.currentTarget.src = "/error_image.png";
             }}
           />
         ))}
       </div>
       <div style={styles.overlay} />
       <div style={styles.actions}>
         <Dropdown overlay={menu} trigger={['click']}>
           <MoreOutlined 
             style={styles.icon}
             onClick={(e) => e.stopPropagation()} 
           />
         </Dropdown>
       </div>
     </>
   );
 };

 return (
   <>
     <Card
       hoverable
       style={styles.card}
       onClick={handleView}
       cover={
         <div 
           style={styles.imageContainer}
           onMouseEnter={() => setIsHovered(true)}
           onMouseLeave={() => setIsHovered(false)}
         >
           {renderThumbnails()}
         </div>
       }
     >
       <div style={styles.iconContainer}>
         <Tooltip title={views === 0 ? '읽지 않음' : `${views}회 조회`}>
           <div style={{ display: 'flex', alignItems: 'center' }}>
             <EyeOutlined style={styles.icon} onClick={handleView} />
             {views > 0 && <span style={{ marginLeft: '5px' }}>{views}</span>}
           </div>
         </Tooltip>
         <Tooltip title={isBookmarked ? '북마크 해제' : '북마크'}>
           {isBookmarked ? (
             <StarFilled onClick={handleBookmark} style={{ ...styles.icon, color: '#fadb14' }} />
           ) : (
             <StarOutlined onClick={handleBookmark} style={styles.icon} />
           )}
         </Tooltip>
       </div>

       <Meta 
         title={
           <div style={{ display: 'flex', alignItems: 'flex-start', gap: '8px' }}>
             <Badge 
               count="Tangle"
               style={{ 
                 backgroundColor: '#b37feb',
                 fontSize: '12px',
                 padding: '0 8px',
                 height: '22px',
                 lineHeight: '22px',
                 borderRadius: '11px',
                 flexShrink: 0
               }} 
             />
             <Popover 
               content={tangle.title} 
               trigger="hover"
               placement="top"
               mouseEnterDelay={0.5} 
             >
               <span style={{ 
                 overflow: 'hidden', 
                 textOverflow: 'ellipsis', 
                 whiteSpace: 'nowrap',
                 cursor: 'pointer'
               }}>
                 {tangle.title}
               </span>
             </Popover>
           </div>
         }
       />
     </Card>
     
     <EditModal
       visible={isEditModalVisible}
       onCancel={() => setIsEditModalVisible(false)}
       onSave={handleEditModalSave}
       initialTitle={tangle.title}
       titleOnly={true}
     />
   </>
 );
};

export default TangleCard;