import axiosInstance from '../utils/axiosInstance';
import axios from 'axios';
import { ObjDoc } from '../types/ChildObj';

export interface UpdatePieceRequest {
    body?: string;
    title?: string;
    keywords?: string[];
}

export const UpdatePiece = {
  updatePieceContent: async (pieceId: string, data: UpdatePieceRequest) => {
    try {
      const response = await axiosInstance.patch(`/api/v1/pieces/${pieceId}/`, data);
      return response;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw error.response?.data?.message || '서버 오류가 발생했습니다.';
      }
      throw '알 수 없는 오류가 발생했습니다.';
    }
  }
};

// 영구 삭제
export const pieceDelete = async (objDoc: ObjDoc): Promise<boolean> => {
  try {
      if (!objDoc._id) {
          throw new Error('Invalid objDoc: missing _id');
      }

      const url = `/api/v1/pieces/${objDoc._id}/`;

      await axiosInstance.delete(url);

      return true;
  } catch (error) {
      if (axios.isAxiosError(error)) {
          // console.error('삭제 처리 중 오류 발생:', error);
      }
      return false;
  }
};

