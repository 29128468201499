import React, { useEffect, useState } from 'react';
import { Row, Col, Spin, Empty } from 'antd';
import TangleCard from '../components/ui/card/TangleCard';
import { getTangleList } from '../api/Tangle'; 
import { Tangle } from '../types/Tangle';

const TanglePage: React.FC = () => {
  const [tangles, setTangles] = useState<Tangle[]>([]);
  const [loading, setLoading] = useState(true);
  const [page] = useState(1);
  const [size] = useState(10);

  const handleEdit = (id: string, newTitle: string) => {
    setTangles(prev => 
      prev.map(tangle => 
        tangle._id === id ? { ...tangle, title: newTitle } : tangle
      )
    );
  };

  useEffect(() => {
    const fetchTangles = async () => {
      try {
        const response = await getTangleList({
          page,
          size
        });
        setTangles(response.items);
      } catch (error) {
        console.error('Failed to fetch tangles:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTangles();
  }, [page, size]);

  if (loading) {
    return (
      <div style={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '100vh' 
      }}>
        <Spin size="large" />
      </div>
    );
  }

  if (!tangles.length) {
    return (
      <div style={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: 'calc(100vh - 64px)', // 헤더 높이를 고려하여 조정
        padding: '24px'
      }}>
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={
            <span style={{ color: '#666' }}>
              아직 작성된 탱글이 없습니다
            </span>
          }
        />
      </div>
    );
  }

  return (
    <div style={{ padding: '24px' }}>
      <Row gutter={[16, 16]}>
        {tangles.map((tangle) => (
          <Col key={tangle._id} xs={24} sm={12} md={8} lg={6}>
            <TangleCard 
              tangle={tangle}
              onEdit={handleEdit}
            />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default TanglePage;