import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Card, Button, message } from 'antd';
import { 
  PlusOutlined, 
  CloseOutlined,
  FileExcelOutlined,
  FileWordOutlined,
  FilePdfOutlined,
  FileImageOutlined,
  FileZipOutlined,
  FileTextOutlined,
  FilePptOutlined,
  FileOutlined,
  FileUnknownOutlined
} from '@ant-design/icons';
import { togglePin } from '../../../api/pin';
import { PinIdBy } from '../../../api/ChildObj'; 
import { Pin } from '../../../types/pin';
import { downloadFile } from '../../../utils/downloadFile'; 

interface PinCardListProps {
    pins: Pin[];
    onAddNew: () => void;
    onRemovePin: (objId: string) => void;
    refreshPins: () => Promise<void>;
}

const cardWidth = 240;
const cardHeight = 220; 
const imageHeight = 160;
const gutter = 24;

const fileIconStyle: React.CSSProperties = {
  fontSize: '80px'
};

const getFileIcon = (fileType: string) => {
  const iconProps = { style: { ...fileIconStyle } };
  const lowerCaseType = fileType.toLowerCase();

  if (lowerCaseType.includes('excel') || lowerCaseType.includes('spreadsheet') || lowerCaseType.endsWith('xlsx') || lowerCaseType.endsWith('xls')) {
    return <FileExcelOutlined {...iconProps} style={{ ...iconProps.style, color: '#217346' }} />;
  } else if (lowerCaseType.includes('word') || lowerCaseType.includes('document') || lowerCaseType.endsWith('docx') || lowerCaseType.endsWith('doc')) {
    return <FileWordOutlined {...iconProps} style={{ ...iconProps.style, color: '#2b579a' }} />;
  } else if (lowerCaseType.includes('pdf')) {
    return <FilePdfOutlined {...iconProps} style={{ ...iconProps.style, color: '#f40f02' }} />;
  } else if (lowerCaseType.includes('image') || ['jpg', 'jpeg', 'png', 'gif'].some(ext => lowerCaseType.endsWith(ext))) {
    return <FileImageOutlined {...iconProps} style={{ ...iconProps.style, color: '#36cfc9' }} />;
  } else if (lowerCaseType.includes('zip') || lowerCaseType.includes('compressed') || ['zip', 'rar', '7z'].some(ext => lowerCaseType.endsWith(ext))) {
    return <FileZipOutlined {...iconProps} style={{ ...iconProps.style, color: '#ffd591' }} />;
  } else if (lowerCaseType.includes('text') || lowerCaseType.endsWith('txt')) {
    return <FileTextOutlined {...iconProps} style={{ ...iconProps.style, color: '#8c8c8c' }} />;
  } else if (lowerCaseType.includes('powerpoint') || lowerCaseType.includes('presentation') || lowerCaseType.endsWith('ppt') || lowerCaseType.endsWith('pptx')) {
    return <FilePptOutlined {...iconProps} style={{ ...iconProps.style, color: '#d04423' }} />;
  } else if (['hwp', 'hwpx', 'hwpml'].some(ext => lowerCaseType.endsWith(ext))) {
    return (
      <div style={{ position: 'relative', display: 'inline-block' }}>
        <FileOutlined {...iconProps} style={{ ...iconProps.style, color: '#10abeb' }} />
        <span style={{ 
          position: 'absolute', 
          top: '60%', 
          left: '50%', 
          transform: 'translate(-50%, -50%)',
          fontSize: '2.5em',
          fontWeight: 600,
          color: '#10abeb'
        }}>
          H
        </span>
      </div>
    );
  } else {
    return <FileUnknownOutlined {...iconProps} style={{ ...iconProps.style, color: '#8c8c8c' }} />;
  }
};

interface PinCardProps extends Pin {
  onRemovePin: (objId: string) => void;
  refreshPins: () => Promise<void>;
}

const PinCard: React.FC<PinCardProps> = ({ thumbnail, title, pinType, oid, fileType, onRemovePin, refreshPins }) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [isHovered, setIsHovered] = useState(false);
  const [messageInfo, setMessageInfo] = useState<{ type: 'success' | 'error', content: string } | null>(null);
  const navigate = useNavigate();
  
  useEffect(() => {
    if (messageInfo) {
      messageApi[messageInfo.type](messageInfo.content);
      setMessageInfo(null);
    }
  }, [messageInfo, messageApi]);

  const handleRemovePin = async (e: React.MouseEvent) => {
    e.stopPropagation();
    try {
      const result = await togglePin(oid);
      if (result) {
        onRemovePin(oid);
        await refreshPins();
        setMessageInfo({ type: 'success', content: '핀이 제거되었습니다.' });
      } else {
        setMessageInfo({ type: 'error', content: '핀 해제 실패' });
      }
    } catch (error) {
      setMessageInfo({ type: 'error', content: '핀 해제 중 오류가 발생했습니다.' });
    }
  };

  const handleCardClick = async () => {
    if (pinType === 'article') {
      try {
        const data = await PinIdBy(oid);
        if (data.linkUrl) {
          window.open(data.linkUrl, '_blank', 'noopener,noreferrer');
        }
      } catch (error) {
        setMessageInfo({ type: 'error', content: '링크를 여는데 실패했습니다.' });
      }
    } else if (pinType === 'piece') {
      try {
        const fullData = await PinIdBy(oid);
        navigate(`/${encodeURIComponent(title)}`, {
          state: { 
            piece: {
              oid,
              title,
              pinType,
              ...fullData,
              createdAt: fullData.createdAt || new Date().toISOString(),
              keywords: fullData.keywords || [],
              linkUrl: fullData.linkUrl || '',
            }
          }
        });
      } catch (error) {
        console.error('Failed to fetch piece data:', error);
        setMessageInfo({ type: 'error', content: '데이터를 불러오는데 실패했습니다.' });
      }
    } else if (pinType === 'file') {
      try {
        await downloadFile(oid);
      } catch (error) {
        console.error('Failed to download file:', error);
        setMessageInfo({ type: 'error', content: '파일 다운로드에 실패했습니다.' });
      }
    }
  };

  return (
    <>
      {contextHolder}
      <Card
        hoverable
        onClick={handleCardClick}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        cover={
          <div style={{ 
            height: 160, 
            overflow: 'hidden', 
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: '#f5f5f5'
          }}>
            {thumbnail ? (
              <img 
                referrerPolicy="no-referrer"
                alt={title} 
                src={thumbnail} 
                style={{ 
                  objectFit: 'cover', 
                  width: '100%', 
                  height: '100%' 
                }} 
              />
            ) : (
              getFileIcon(fileType || '')
            )}
            <Button 
              type="text" 
              shape="circle" 
              icon={<CloseOutlined />} 
              onClick={handleRemovePin} 
              style={{
                position: 'absolute',
                top: 8,
                right: 8,
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '32px',
                height: '32px',
                opacity: isHovered ? 1 : 0,
                transition: 'opacity 0.3s ease',
              }}
            />
          </div>
        }
        styles={{ 
          body: {
            padding: '8px',
            height: 60,
          }
        }}
        style={{ 
          width: '100%', 
          height: 220,
        }}
      >
        <Card.Meta 
          title={title} 
          style={{ 
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        />
      </Card>
    </>
  );
};

const AddNewCard: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <Card
      hoverable
      style={{
        width: '100%',
        height: cardHeight,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer'
      }}
      onClick={onClick}
    >
      <PlusOutlined style={{ fontSize: '2.5rem', color: '#8c8c8c' }} />
      <div style={{ marginTop: '8px' }}>
        <Card.Meta style={{ textAlign: 'center' }} />
      </div>
    </Card>
  );
};

const PinCardList: React.FC<PinCardListProps> = ({ pins, onAddNew, onRemovePin, refreshPins }) => {
  return (
    <div style={{ padding: `0 ${gutter / 2}px`, maxWidth: '1200px', margin: '0 auto' }}>
      <Row 
        gutter={[gutter, gutter]} 
        justify="center"
        style={{ 
          margin: '0 auto'
        }}
      >
        {pins.map((pin) => (
          <Col 
            key={pin.oid} 
            style={{ 
              width: cardWidth,
              marginBottom: gutter 
            }}
          >
            <PinCard {...pin} onRemovePin={onRemovePin} refreshPins={refreshPins} />
          </Col>
        ))}
        {pins.length < 8 && (
          <Col 
            style={{ 
              width: cardWidth,
              marginBottom: gutter 
            }}
          >
            <AddNewCard onClick={onAddNew} />
          </Col>
        )}
      </Row>
    </div>
  );
};

export default PinCardList;