import React, { useState, useEffect, useCallback } from 'react';
import { Typography, message } from 'antd';
import Search from './ui/Search/Search';
import PinCardList from './ui/card/PinCardList';
import AddPinModal from './ui/modal/AddPinModal ';
import { Pin } from '../types/pin';
import { getPins } from '../api/pin';
import styles from '../styles/PinComponent.module.css';

const { Title } = Typography;

const PinComponent: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [pins, setPins] = useState<Pin[]>([]);
  const [logoSize, setLogoSize] = useState({ width: 400, height: 160 });

  // 로고 크기 조정을 위한 useEffect
  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width <= 480) {
        setLogoSize({ width: 280, height: 112 });
      } else if (width <= 768) {
        setLogoSize({ width: 340, height: 136 });
      } else {
        setLogoSize({ width: 400, height: 160 });
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // 초기 크기 설정

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const fetchPins = useCallback(async () => {
    try {
      const fetchedPins = await getPins();
      setPins(fetchedPins);
    } catch (error) {
      message.error('핀 데이터를 가져오는 중 오류가 발생했습니다.');
    }
  }, []);

  useEffect(() => {
    fetchPins();
  }, [fetchPins]);

  const handleAddNew = () => {
    setIsModalVisible(true);
  };

  const handleRemovePin = async (objId: string) => {
    await fetchPins();
  };

  const handleAddPin = async (newPin: Pin): Promise<void> => {
    setIsModalVisible(false);
    await fetchPins();
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        maxWidth: '1200px',
        margin: '0 auto',
        padding: '0',
        boxSizing: 'border-box',
        minHeight: '100%',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          gap: '40px',
        }}
      >
        <img
          src="/images/logo-long.png"
          alt="Ceep"
          style={{
            height: `${logoSize.height}px`,
            width: `${logoSize.width}px`,
            transition: 'all 0.3s ease',
            maxWidth: '100%',
            objectFit: 'contain',
          }}
        />
        <Search
          size="large"
          style={{
            width: 'calc(100% - 40px)',
            maxWidth: '700px',
            margin: '0 auto',
          }}
        />
        <div 
          className={styles.customScrollbar}
          style={{ 
            width: '100%',
            maxHeight: 'calc(100vh - 400px)',
            overflowY: 'auto',
          }}
        >
          <PinCardList
            pins={pins}
            onAddNew={handleAddNew}
            onRemovePin={handleRemovePin}
            refreshPins={fetchPins}
          />
          <AddPinModal
            isVisible={isModalVisible}
            onCancel={() => setIsModalVisible(false)}
            onAddPin={handleAddPin}
            pinnedItems={pins}
            refreshPins={fetchPins}
          />
        </div>
      </div>
    </div>
  );
};

export default PinComponent;