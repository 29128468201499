import React, { useState, useCallback } from 'react';
import { BubbleMenu, Editor } from '@tiptap/react';

interface EditorBubbleMenuProps {
  editor: Editor;
}

const colors = [
  '#000000', '#434343', '#666666',
  '#e11d48', '#ea580c', '#ca8a04',
  '#16a34a', '#2563eb', '#7c3aed',
  '#db2777'
];

const highlights = [
  '#fecaca', '#fed7aa', '#fef08a',
  '#bbf7d0', '#bfdbfe', '#ddd6fe',
  '#fbcfe8'
];

export const EditorBubbleMenu: React.FC<EditorBubbleMenuProps> = ({ editor }) => {
  const [showColors, setShowColors] = useState(false);
  const [showHighlights, setShowHighlights] = useState(false);
  const [showLink, setShowLink] = useState(false);
  const [showListMenu, setShowListMenu] = useState(false);
  const [showAlignMenu, setShowAlignMenu] = useState(false);
  const [linkUrl, setLinkUrl] = useState('');

  const addLink = useCallback(() => {
    if (linkUrl) {
      editor.chain().focus().setLink({ href: linkUrl }).run();
    }
    setShowLink(false);
    setLinkUrl('');
  }, [editor, linkUrl]);

  const removeLink = useCallback(() => {
    editor.chain().focus().unsetLink().run();
    setShowLink(false);
  }, [editor]);

  const closeAllMenus = useCallback((except?: string) => {
    if (except !== 'colors') setShowColors(false);
    if (except !== 'highlights') setShowHighlights(false);
    if (except !== 'link') setShowLink(false);
    if (except !== 'list') setShowListMenu(false);
    if (except !== 'align') setShowAlignMenu(false);
  }, []);

  if (!editor) {
    return null;
  }

  return (
    <BubbleMenu 
      editor={editor}
      tippyOptions={{ 
        duration: 100,
        placement: 'top',
        zIndex: 30
      }}
    >
      <div className="editor-bubble-menu">
        <button
          onClick={() => editor.chain().focus().toggleBold().run()}
          className={`menu-item ${editor.isActive('bold') ? 'is-active' : ''}`}
        >
          <b>B</b>
        </button>
        <button
          onClick={() => editor.chain().focus().toggleItalic().run()}
          className={`menu-item ${editor.isActive('italic') ? 'is-active' : ''}`}
        >
          <i>I</i>
        </button>
        <button
          onClick={() => editor.chain().focus().toggleUnderline().run()}
          className={`menu-item ${editor.isActive('underline') ? 'is-active' : ''}`}
        >
          <u>U</u>
        </button>
        <button
          onClick={() => editor.chain().focus().toggleStrike().run()}
          className={`menu-item ${editor.isActive('strike') ? 'is-active' : ''}`}
        >
          <s>S</s>
        </button>
        <button
          onClick={() => editor.chain().focus().toggleCode().run()}
          className={`menu-item ${editor.isActive('code') ? 'is-active' : ''}`}
        >
          <code>{'</>'}</code>
        </button>

        <div className="divider" />

        {/* List Dropdown */}
        <div className="dropdown-wrapper">
          <button
            onClick={() => {
              closeAllMenus('list');
              setShowListMenu(!showListMenu);
            }}
            className={`menu-item ${
              (editor.isActive('bulletList') || editor.isActive('orderedList')) ? 'is-active' : ''
            }`}
            title="리스트"
          >
            <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor">
              <line x1="8" y1="12" x2="21" y2="12" strokeWidth="2"/>
              <circle cx="4" cy="12" r="2" fill="currentColor"/>
            </svg>
          </button>
          {showListMenu && (
            <div className="dropdown-menu">
              <button
                onClick={() => {
                  editor.chain().focus().toggleBulletList().run();
                  setShowListMenu(false);
                }}
                className={`dropdown-item ${editor.isActive('bulletList') ? 'is-active' : ''}`}
              >
                <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                  <line x1="8" y1="6" x2="21" y2="6" strokeWidth="2"/>
                  <line x1="8" y1="12" x2="21" y2="12" strokeWidth="2"/>
                  <line x1="8" y1="18" x2="21" y2="18" strokeWidth="2"/>
                  <circle cx="4" cy="6" r="2" fill="currentColor"/>
                  <circle cx="4" cy="12" r="2" fill="currentColor"/>
                  <circle cx="4" cy="18" r="2" fill="currentColor"/>
                </svg>
              </button>
              <button
                onClick={() => {
                  editor.chain().focus().toggleOrderedList().run();
                  setShowListMenu(false);
                }}
                className={`dropdown-item ${editor.isActive('orderedList') ? 'is-active' : ''}`}
              >
                <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                  <line x1="10" y1="6" x2="21" y2="6" strokeWidth="2"/>
                  <line x1="10" y1="12" x2="21" y2="12" strokeWidth="2"/>
                  <line x1="10" y1="18" x2="21" y2="18" strokeWidth="2"/>
                  <text x="2" y="7" fontSize="10" fill="currentColor">1</text>
                  <text x="2" y="13" fontSize="10" fill="currentColor">2</text>
                  <text x="2" y="19" fontSize="10" fill="currentColor">3</text>
                </svg>
              </button>
            </div>
          )}
        </div>

        {/* Align Dropdown */}
        <div className="dropdown-wrapper">
          <button
            onClick={() => {
              closeAllMenus('align');
              setShowAlignMenu(!showAlignMenu);
            }}
            className={`menu-item ${
              editor.isActive({ textAlign: 'left' }) || 
              editor.isActive({ textAlign: 'center' }) ? 'is-active' : ''
            }`}
            title="정렬"
          >
            <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor">
              <line x1="3" y1="6" x2="21" y2="6" strokeWidth="2"/>
              <line x1="3" y1="12" x2="21" y2="12" strokeWidth="2"/>
              <line x1="3" y1="18" x2="21" y2="18" strokeWidth="2"/>
            </svg>
          </button>
          {showAlignMenu && (
            <div className="dropdown-menu">
              <button
                onClick={() => {
                  editor.chain().focus().setTextAlign('left').run();
                  setShowAlignMenu(false);
                }}
                className={`dropdown-item ${editor.isActive({ textAlign: 'left' }) ? 'is-active' : ''}`}
              >
                <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                  <line x1="3" y1="6" x2="21" y2="6" strokeWidth="2"/>
                  <line x1="3" y1="12" x2="15" y2="12" strokeWidth="2"/>
                  <line x1="3" y1="18" x2="18" y2="18" strokeWidth="2"/>
                </svg>
              </button>
              <button
                onClick={() => {
                  editor.chain().focus().setTextAlign('center').run();
                  setShowAlignMenu(false);
                }}
                className={`dropdown-item ${editor.isActive({ textAlign: 'center' }) ? 'is-active' : ''}`}
              >
                <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                  <line x1="3" y1="6" x2="21" y2="6" strokeWidth="2"/>
                  <line x1="6" y1="12" x2="18" y2="12" strokeWidth="2"/>
                  <line x1="4" y1="18" x2="20" y2="18" strokeWidth="2"/>
                </svg>
              </button>
              <button
                onClick={() => {
                  editor.chain().focus().setTextAlign('right').run();
                  setShowAlignMenu(false);
                }}
                className={`dropdown-item ${editor.isActive({ textAlign: 'right' }) ? 'is-active' : ''}`}
              >
                <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                  <line x1="3" y1="6" x2="21" y2="6" strokeWidth="2"/>
                  <line x1="9" y1="12" x2="21" y2="12" strokeWidth="2"/>
                  <line x1="6" y1="18" x2="21" y2="18" strokeWidth="2"/>
                </svg>
              </button>
            </div>
          )}
        </div>

        <div className="divider" />

        {/* Color picker */}
        <div className="color-dropdown">
          <button
            onClick={() => {
              closeAllMenus('colors');
              setShowColors(!showColors);
            }}
            className="menu-item color-button"
            title="텍스트 색상"
          >
            A
            <span 
              className="color-dot" 
              style={{ backgroundColor: editor.getAttributes('textStyle').color || '#000' }} 
            />
          </button>
          {showColors && (
            <div className="color-picker">
              {colors.map((color) => (
                <button
                  key={color}
                  onClick={() => {
                    editor.chain().focus().setColor(color).run();
                    setShowColors(false);
                  }}
                  className="color-item"
                  style={{ backgroundColor: color }}
                  title={color}
                />
              ))}
              <button
                onClick={() => {
                  editor.chain().focus().unsetColor().run();
                  setShowColors(false);
                }}
                className="color-item clear-color"
                title="색상 제거"
              >
                <svg width="16" height="16" viewBox="0 0 24 24" stroke="currentColor" fill="none">
                  <line x1="5" y1="5" x2="19" y2="19" strokeWidth="2" />
                </svg>
              </button>
            </div>
          )}
        </div>

        {/* Highlight picker */}
        <div className="color-dropdown">
          <button
            onClick={() => {
              closeAllMenus('highlights');
              setShowHighlights(!showHighlights);
            }}
            className="menu-item highlight-button"
            title="하이라이트"
          >
            <span className="highlight-icon">H</span>
          </button>
          {showHighlights && (
            <div className="color-picker">
              {highlights.map((color) => (
                <button
                  key={color}
                  onClick={() => {
                    editor.chain().focus().setHighlight({ color }).run();
                    setShowHighlights(false);
                  }}
                  className="color-item"
                  style={{ backgroundColor: color }}
                  title={color}
                />
              ))}
              <button
                onClick={() => {
                  editor.chain().focus().unsetHighlight().run();
                  setShowHighlights(false);
                }}
                className="color-item clear-color"
                title="하이라이트 제거"
              >
                <svg width="16" height="16" viewBox="0 0 24 24" stroke="currentColor" fill="none">
                  <line x1="5" y1="5" x2="19" y2="19" strokeWidth="2" />
                </svg>
              </button>
            </div>
          )}
        </div>

        {/* Link */}
        <div className="link-dropdown">
          <button
            onClick={() => {
              closeAllMenus('link');
              setShowLink(!showLink);
            }}
            className={`menu-item ${editor.isActive('link') ? 'is-active' : ''}`}
            title="링크"
          >
            <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor">
              <path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71" strokeWidth="2"/>
              <path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71" strokeWidth="2"/>
            </svg>
          </button>
          {showLink && (
            <div className="link-popup">
              <input
                type="text"
                placeholder="URL을 입력하세요"
                value={linkUrl}
                onChange={(e) => setLinkUrl(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    addLink();
                  }
                }}
              />
              <div className="link-buttons">
                <button onClick={addLink} className="link-button">추가</button>
                {editor.isActive('link') && (
                  <button onClick={removeLink} className="link-button remove">제거</button>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </BubbleMenu>
  );
};

export default EditorBubbleMenu;