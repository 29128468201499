import React, { createContext, useState, useEffect, useContext, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { signout } from '../api/auth'; 
import axiosInstance from '../utils/axiosInstance';
import { useQueryClient } from '@tanstack/react-query';


interface AuthState {
    accessToken: string | null;
    refreshToken: string | null;
    tokenType: string | null;
    isAuthenticated: boolean;
    loading: boolean;
    error: string | null;
    user: any | null;
    showLoginModal: boolean;
}

interface AuthContextType extends AuthState {
    login: (accessToken: string, refreshToken: string, tokenType: string) => void;
    logout: () => void;
    checkAuth: () => void;
    handleTokenRefresh: () => Promise<void>;
    handleTokenRefreshCancel: () => void;
    setShowLoginModal: (show: boolean) => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [authState, setAuthState] = useState<AuthState>({
        accessToken: null,
        refreshToken: null,
        tokenType: null,
        isAuthenticated: false,
        loading: true,
        error: null,
        user: null,
        showLoginModal: false
    });
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const login = useCallback((accessToken: string, refreshToken: string, tokenType: string) => {
        Cookies.set('accessToken', accessToken, { secure: true, sameSite: 'strict' });
        Cookies.set('refreshToken', refreshToken, { secure: true, sameSite: 'strict' });
        Cookies.set('tokenType', tokenType, { secure: true, sameSite: 'strict' });
        setAuthState(prev => ({ 
            ...prev, 
            accessToken, 
            refreshToken, 
            tokenType,
            isAuthenticated: true, 
            loading: false 
        }));
    }, []);

    const logout = useCallback(async () => {
        try {
            const response = await signout();

            if (response.result) {
                handleLocalLogout();
            } else {
                // 서버에서 false를 반환했지만, 클라이언트 측에서는 로그아웃 처리를 진행.
                handleLocalLogout();
            }
        } catch (error) {
            if (error instanceof Error) {
                // console.error('Error message:', error.message);
            }
            // 에러가 발생해도 로컬 상태는 초기화
            handleLocalLogout();
        }
    }, [navigate]);
      
    const handleLocalLogout = useCallback(() => {
        Cookies.remove('accessToken');
        Cookies.remove('refreshToken');
        Cookies.remove('tokenType');
        localStorage.removeItem('SESSION_KEY');
        localStorage.removeItem('_u'); 
        sessionStorage.removeItem('user');
        queryClient.clear();
        setAuthState(prev => ({
          ...prev,
          accessToken: null,
          refreshToken: null,
          tokenType: null,
          isAuthenticated: false,
          user: null,
          loading: false
        }));
        navigate('/');
    }, [navigate, queryClient]);

    const checkAuth = useCallback(() => {
        const accessToken = Cookies.get('accessToken');
        const refreshToken = Cookies.get('refreshToken');
        const tokenType = Cookies.get('tokenType');
        const isAuthenticated = !!(accessToken && refreshToken && tokenType);
    
        setAuthState(prev => ({ 
            ...prev, 
            accessToken: accessToken || null,
            refreshToken: refreshToken || null,
            tokenType: tokenType || null,
            isAuthenticated,
            loading: false
        }));
    
        return isAuthenticated;
    }, []);

    const handleTokenRefresh = useCallback(async () => {
        try {
            const refreshToken = Cookies.get('refreshToken');
            const result = await axiosInstance.post('/refresh', { refreshToken });
            if (result.data.access_token && result.data.refresh_token && result.data.token_type) {
                login(result.data.access_token, result.data.refresh_token, result.data.token_type);
                setAuthState(prev => ({ ...prev, showLoginModal: false }));
            } else {
                throw new Error('Failed to refresh token');
            }
        } catch (error) {
            // console.error('Token refresh failed:', error);
            logout();
            // navigate('/signin');
            navigate('/cbetasi');
        }
    }, [login, logout, navigate]);

    const handleTokenRefreshCancel = useCallback(() => {
        setAuthState(prev => ({ ...prev, showLoginModal: false }));
        logout();
        // navigate('/signin');
        navigate('/cbetasi');
    }, [logout, navigate]);

    const setShowLoginModal = useCallback((show: boolean) => {
        setAuthState(prev => ({ ...prev, showLoginModal: show }));
    }, []);

    useEffect(() => {
        checkAuth();
    }, []);

    useEffect(() => {
        const handleTokenExpired = () => {
            setShowLoginModal(true);
        };

        window.addEventListener('tokenExpired', handleTokenExpired);
        window.addEventListener('logout', logout);

        return () => {
            window.removeEventListener('tokenExpired', handleTokenExpired);
            window.removeEventListener('logout', logout);
        };
    }, [logout]);

    const contextValue = useMemo(() => ({
        ...authState, 
        login, 
        logout, 
        checkAuth, 
        handleTokenRefresh,
        handleTokenRefreshCancel,
        setShowLoginModal
    }), [authState, login, logout, checkAuth, handleTokenRefresh, handleTokenRefreshCancel, setShowLoginModal]);

    return (
        <AuthContext.Provider value={contextValue}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};