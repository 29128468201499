import React from 'react';
import { Modal } from 'antd';

interface HelpModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const HelpModal: React.FC<HelpModalProps> = ({ isOpen, onClose }) => {
  return (
    <Modal
      title="도움말"
      open={isOpen}
      onCancel={onClose}
      footer={null}
      centered
    >
      <div style={{ padding: '20px 0' }}>
        <h3>기본 사용법</h3>
        <p>• 북마크: 자주 사용하는 문서를 저장할 수 있습니다.</p>
        <p>• 퀵클립: 빠른 메모와 문서를 관리할 수 있습니다.</p>
        <p>• 아카이브: 장기 보관이 필요한 문서를 저장할 수 있습니다.</p>
        
        <h3>단축키</h3>
        <p>• ⌘/Ctrl + F: 문서 검색</p>
        <p>• ⌘/Ctrl + N: 새 문서 작성</p>
        <p>• ⌘/Ctrl + S: 문서 저장</p>
        
        <h3>지원</h3>
        <p>추가 도움이 필요하시다면 support@example.com으로 문의해주세요.</p>
      </div>
    </Modal>
  );
};

export default HelpModal;